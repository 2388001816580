import Select, { ValueType } from 'react-select';
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { ActionMetaExtra, KLMultiSelectValue } from './types';
import { useClearIndicator, useMultiValue } from './components';
import { customStyles, getValue, onChangeChecked } from './helpers';

const useStyles = makeStyles(styles);

export type SelectAsyncProps = {
  value: KLMultiSelectValue[];
  options: KLMultiSelectValue[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  load?: (searchPhrase: string) => void;
  loading?: boolean;
  isIdle?: boolean;
}

export const SelectCached = (props: SelectAsyncProps) => {
  const { t } = useTranslation('common');
  const { value, options, isMulti = true,
    onChange, placeholder = t('Select'), disabled,
    load = () => undefined,
    loading = false,
    isIdle = true,
  } = props;
  const classes = useStyles();
  const onMenuAyncOpen = useCallback(() => {
    if (!isIdle) return;
    if (load) {
      load('');
    }
  }, [isIdle, load]);
  const MultiValue = useMultiValue({});
  const ClearIndicator = useClearIndicator();

  const handler = onChangeChecked({
    value: value.map(getValue),
    options: options.map(getValue),
    onChange,
  });
  return (
    (
      <Select
        className={classes.root}
        components={{
          DropdownIndicator: null,
          ClearIndicator,
          MultiValue,
        }}
        isSearchable={false}
        onChange={(_, meta) => handler({
          rawMeta: meta as ActionMetaExtra,
          isMulti,
          value: _ as unknown as ValueType<KLMultiSelectValue>,
        })}
        isMulti={isMulti}
        isClearable
        filterOption={() => true}
        options={options}
        isLoading={loading}
        placeholder={placeholder}
        value={value}
        styles={customStyles}
        noOptionsMessage={() => t('No options')}
        loadingMessage={() => t('Loading...')}
        isDisabled={disabled}
        openMenuOnClick
        onMenuOpen={onMenuAyncOpen}
      />
    )
  );
};
