import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { LicensesState, LicenseItemsRequest, LicenseItem } from './types';

const initialState: LicensesState = {
  licenses: [],
  error: undefined,
  isLoading: false,
  request: undefined,
  columns: {},
  filters: {},
};

export const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    licensesRequested(state: LicensesState, { payload }: PayloadAction<LicenseItemsRequest>) {
      state.request = payload;
      state.isLoading = true;
    },
    licensesResolved(
      state: LicensesState, 
      { payload: { licenses } }:
        PayloadAction<{licenses: LicenseItem[]}>,
    ) {
      state.licenses = licenses;
      state.isLoading = false;
    },
    licensesRejected(state: LicensesState, { payload }: PayloadAction<any>) {
      state.isLoading = false;
      state.licenses = [];
      state.error = payload;
    },
    setColumns(state: LicensesState, action: PayloadAction<any | null>) {
      state.columns = action.payload;
    },
    setFilters(state: LicensesState, action: PayloadAction<any | null>) {
      state.filters = action.payload;
    },
  },
});

export const { licensesResolved, licensesRejected, licensesRequested, setColumns, setFilters } = licensesSlice.actions;

export const requestLicenses = createAction<LicenseItemsRequest>('request-licenses');

export const downloadLicenseTenantReport = createAction('download-license-tenant-report');

export default licensesSlice.reducer;
