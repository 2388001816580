import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Duration, endOfDay, startOfDay, sub, format } from 'date-fns';
import { DateRange, StaticDateRangePicker, DateRangeDelimiter } from '@material-ui/pickers';
import { Box, Popover, SelectProps, StandardProps, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KLSelect } from 'components/KLSelect';
import { KLButton } from 'components/KLButton';
import { KLInput } from 'components/KLInput';
import { DateTime } from 'components/DateTime';
import { KLDatePickerProps, KLDateType } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

const roundTimeToDay = (date: any) => {
  const result = new Date(date.getTime());
  result.setMilliseconds(0);
  result.setSeconds(0);
  result.setMinutes(0);
  result.setHours(0);
  result.setHours(0);
  return result;
};

export const KLDatePicker: React.FC<StandardProps<(KLDatePickerProps & {id?: string}), any>> = props => {
  const {
    onChange,
    start,
    end,
    type,
    useTextInput,
    minDate,
    maxDate,
    fixedStartDate,
    disabled,
    error,
    ...restProps
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('dates');
  const ref = useRef<HTMLUListElement | null>(null);
  const ref2 = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLUListElement | HTMLInputElement | null>(null);
  const startDate = start && type === KLDateType.custom ? new Date(start) : null;
  const endDate = end && type === KLDateType.custom ? new Date(end) : null;
  const [selectedDate, handleDateChange] = useState<DateRange>([startDate, endDate]);

  const items: { label: string; value: KLDateType }[] = [
    { label: t('filters.All time'), value: KLDateType.all },
    { label: t('filters.Last day'), value: KLDateType.day },
    { label: t('filters.Last week'), value: KLDateType.week },
    { label: t('filters.Last month'), value: KLDateType.month },
    { label: t('filters.Last year'), value: KLDateType.year },
    { label: t('filters.Custom period'), value: KLDateType.custom },
  ];
  const openPopover = Boolean(anchorEl);

  const handlePopoverClose = () => setAnchorEl(null);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value as KLDateType;
    let duration: Duration;

    if (type === KLDateType.custom) return;

    switch (type) {
      case KLDateType.day:
        duration = { days: 1 };
        break;
      case KLDateType.week:
        duration = { weeks: 1 };
        break;
      case KLDateType.month:
        duration = { months: 1 };
        break;
      case KLDateType.year:
        duration = { years: 1 };
        break;
      default:
        duration = {};
    }

    handleDateChange([null, null]);
    onChange({
      start: type === KLDateType.all ? null : sub(Date.now(), duration).getTime(),
      end: null,
      type,
    });
  };

  const handleCustomPeriodChange = () => {
    const [startDate, endDate] = selectedDate;
    const start = startDate ? startOfDay(startDate).getTime() : null;
    const end = endDate ? endOfDay(endDate).getTime() : null;

    onChange({
      type: KLDateType.custom,
      start,
      end,
    });
  };

  const RenderSelectValue = (value: SelectProps['value']): React.ReactNode => {
    const [startDate, endDate] = selectedDate;
    const type = value as KLDateType;

    if (type !== KLDateType.custom) {
      return items.find(item => item.value === type)?.label;
    }

    if (fixedStartDate) {
      return endDate && <DateTime timestamp={endDate.getTime()} withTime={false} />;
    }

    return (
      <>
        {startDate && <DateTime timestamp={startDate.getTime()} withTime={false} />}
        {' - '}
        {endDate && <DateTime timestamp={endDate.getTime()} withTime={false} />}
      </>
    );
  };

  const formatedInputValue = useMemo(() => {
    const [startDate, endDate] = selectedDate;
    const start = startDate ? format(new Date(startDate), t('formats.fullDate')) : '';
    const end = endDate ? format(new Date(endDate), t('formats.fullDate')) : '';
    if (fixedStartDate) {
      return `${end}`;
    }
    return `${start} - ${end}`;
  }, [selectedDate[0], selectedDate[1]]);

  return (
    <div className={classes.root}>
      {
        useTextInput ? (
          <KLInput
            disabled={disabled}
            readOnly
            error={error}
            // noMarginTop
            fullWidth
            ref={ref2}
            value={formatedInputValue}
            onClick={() => {
              setAnchorEl(ref2.current);
            }}
            id={restProps.id ? restProps.id : undefined}
          />
        ) : (
          <KLSelect
            {...restProps}
            disabled={disabled}
            ref={ref}
            error={error}
            items={items}
            value={type}
            onChange={handleSelectChange}
            onClose={(event) => {
              const type = (event.target as HTMLDataListElement).dataset.value as KLDateType;
              if (type === KLDateType.custom) {
                setAnchorEl(ref.current);
              }
            }}
            renderValue={RenderSelectValue}
          />
        )
      }

      <Popover
        id={Math.random().toString(36).substring(7)}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        classes={{ paper: classes.popoverPaper }}
      >
        <StaticDateRangePicker
          // variant="standard"
          displayStaticWrapperAs="desktop"
          value={selectedDate}
          onChange={date => {
            if (!fixedStartDate) {
              return handleDateChange(date);
            }

            // dirty hack for fixed start date
            // its need for Bug 4567108: [TEST] Remove tenant lifetime start date
            if (!date[1] || (startDate && date[0]
              && (roundTimeToDay(startDate).getTime() !== roundTimeToDay(date[0]).getTime())
            )) {
              handleDateChange([startDate, date[0]]);
            } else {
              handleDateChange([startDate, date[1]]);
            }
            return null;
          }}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : new Date()}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <DateRangeDelimiter> to </DateRangeDelimiter>
              <TextField {...endProps} />
            </>
          )}
        />
        <Box className={classes.controls}>
          <KLButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleCustomPeriodChange();
              handlePopoverClose();
            }}
            disabled={!selectedDate[0] || !selectedDate[1]}
          >
            {t('Ok')}
          </KLButton>
          <KLButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={handlePopoverClose}
          >
            {t('Cancel')}
          </KLButton>
        </Box>
      </Popover>
    </div>
  );
};

export * from './types';
