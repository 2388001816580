import React, { ComponentType } from 'react';
import { components, OptionProps } from 'react-select';
import { KLMultiSelectValue } from '../types';
import { CheckIcon } from 'assets/icons/CheckIcon';
import { makeStyles } from '@material-ui/core';
import styles from '../styles';


const useStyles = makeStyles(styles);
export const useOption = (): ComponentType<OptionProps<KLMultiSelectValue>> => {
  const classes = useStyles();
  return (props) => {
    const { isSelected, label } = props;
    return (
      <div>
        <components.Option {...props}>
          <div className={classes.checkOption}>
            <label>{label}</label>
            {isSelected && <CheckIcon />}
          </div>
        </components.Option>
      </div>
    );
  };
};
