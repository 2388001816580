import { TFunction } from 'i18next';
import { TenantItem } from 'services/tenants/types';

export function translateRootTenantName(tenants: TenantItem[], t: TFunction) {
  return tenants?.map((tenant: TenantItem) => {
    if (tenant.tenantId === '-') {
      return { ...tenant, name: t('Root tenant'), description: t('Root tenant') };
    }
    return tenant;
  });
}
