import { ActionToInfoResult, ValuesOnSelectProps } from '../types';
import { formatMeta, ToCorrectMetaStructureProps } from './formatMeta';

export const valuesOnMultiOptionSelect = (props: ValuesOnSelectProps): string[] => {
  const { values,
    meta,
  } = props;
  switch (meta.action) {
    case 'clear': {
      return [];
    }
    case 'deselect-option':
    case 'remove-value': {
      return values.filter(e => e !== meta.option.value);
    }
    case 'select-option': {
      return values.concat(meta.option.value);
    }
    default: {
      return values;
    }
  }
};

export const valuesOnOneOptionSelect = (props: ValuesOnSelectProps): string[] => {
  const {
    values,
    meta,
  } = props;
  switch (meta.action) {
    case 'clear': {
      return [];
    }
    case 'deselect-option': {
      return [];
    }
    case 'select-option': {
      return values;
    }
    default: return [];
  }
};

export const valuesFormat = (props: ValuesOnSelectProps): ActionToInfoResult => {
  const {
    values,
    meta,
  } = props;
  if (meta.action !== 'select-option') {
    return meta;
  }
  if (!values.includes(meta.option.value)) {
    return meta;
  }
  return { ...meta, action: 'deselect-option' };
};

export type UpdateOnMultiOptionProps = {
  value: string[];
  onChange: (args: string[]) => void;
  meta: ActionToInfoResult;
}
export const updateOnMultiOption = (props: UpdateOnMultiOptionProps) => {
  const { value, onChange, meta } = props;

  const newValues = valuesOnMultiOptionSelect({ meta, values: value });
  return onChange(newValues);
};

export type UpdateOnAllOptionProps = {
  options: string[];
  onChange: (args: string[]) => void;
  meta: ActionToInfoResult;
}
export const updateOnAllOption = (props: UpdateOnAllOptionProps) => {
  const { onChange, options, meta } = props;

  const newValues = valuesOnOneOptionSelect({ meta, values: options });
  return onChange(newValues);
};

export type UpdateOnOneOptionProps = {
  onChange: (args: string[]) => void;
  meta: ActionToInfoResult;
}
export const updateOnOneOption = (props: UpdateOnOneOptionProps) => {
  const { onChange, meta } = props;

  const newValues = valuesOnOneOptionSelect({ meta, values: [meta.option.value] });
  return onChange(newValues);
};

export type OnChangeCheckedProps = {
  value: string[];
  options: string[];
  onChange: (args: string[]) => void;
  selectAllValue?: string;
};
export const onChangeChecked = (props: OnChangeCheckedProps) => (metaInfo: ToCorrectMetaStructureProps) => {
  const { value, onChange,
    options, selectAllValue = '*',
  } = props;
  const { isMulti = true } = metaInfo;
  const meta = formatMeta({ metaInfo, values: value });

  if (!isMulti) {
    return updateOnOneOption({ meta, onChange });
  }
  if (meta.option.value === selectAllValue) {
    return updateOnAllOption({ meta, onChange, options });
  }
  return updateOnMultiOption({ meta, onChange, value });
};
