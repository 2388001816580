import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { geImageUrl } from 'services/cache/api';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { isPendingCacheItem, isRejectedCacheItem } from 'services/cache/helpers';
import { Loading } from 'components/Loading';

const useStyles = makeStyles(styles);

type CustomImageLoadedProps = Partial<HTMLImageElement> & { clientId: string; secret: string, attachmentId: string };
export const CustomImageLoaded = (props: CustomImageLoadedProps) => {
  const { clientId, secret, src, alt, attachmentId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const imageItem = useSelector(store => (store.cache.images.find(e => e.id === attachmentId)));

  useEffect(() => {
    dispatch(geImageUrl({
      id: attachmentId, requestInfo: {
        attachmentId, clientId, secret
      }
    }));
  }, [clientId, secret, src]);

  if (!imageItem) {
    return null
  };
  if (isPendingCacheItem(imageItem)) {
    return <Loading />
  };
  if (isRejectedCacheItem(imageItem)) {
    return <>{imageItem.data}</>
  };

  return <img className={classes.image} src={imageItem.data.value} alt={alt} />;
};
