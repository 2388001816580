import React, { CSSProperties, useEffect, useMemo, useState, useCallback, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload, ActionCreatorWithPreparedPayload } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam, withDefault } from 'use-query-params';
import { debounce } from 'throttle-debounce';
import get from 'lodash.get';
import update from 'immutability-helper';
import isEqual from 'react-fast-compare';
import MaterialTable, {
  Icons,
  MaterialTableProps,
  MTableHeader,
  MTableBodyRow,
  MTableActions,
  MTableCell,
  Column,
} from 'material-table';
import { Badge } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  ViewColumn,
} from '@material-ui/icons';
import { EntityColumn } from 'store/slice';
import { ArrowSortIcon } from 'assets/icons/ArrowSortIcon';
import { FilterIcon } from 'assets/icons/FilterIcon';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { CogwheelIcon } from 'assets/icons/CogwheelIcon';
import { Title } from 'components/Title';
import { useFilterQueryParams, FilterField, FilterType } from 'components/Filter';
import { Toolbar, Pagination, TableFilter, TableColumns, Search } from './components';
import styles from './styles';

const MIN_SEARCH_STR_LENGTH = 2;
const MAX_SEARCH_STR_LENGTH = 256;

const tableIcons: Icons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <CloseIcon {...props} ref={ref} size={12} strokeWidth={1} />),
  Search: React.forwardRef((props, ref) => <SearchIcon {...props} ref={ref} fill="#58595B" />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowSortIcon {...props} ref={ref} style={{ margin: '0 8px' }} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

type Paging = {
  page: number;
  pageSize: number;
};

export type Payload<F> = {
  paging: Paging;
  filter?: F;
  searchPhrase?: string;
};

type ColumnSettings<T> = {
  [K in keyof T | string]?: EntityColumn;
};

export type TableColumn<T extends object> = Column<T> & { locator?: string };

export type TableProps<T extends object, F extends FilterType<F>, C extends ColumnSettings<T>> =
  React.PropsWithChildren<MaterialTableProps<T>> &
  {
    action?: ActionCreatorWithPayload<Payload<F>> | ActionCreatorWithPreparedPayload<any, Payload<F>>;
    setPageToStore?: ActionCreatorWithPayload<number>;
    setPageSizeToStore?: ActionCreatorWithPayload<number>;
    count: number;
    route?: string;
    routeId?: keyof T;
    isLoadable?: boolean;
    transNamespace?: string;
    filterFields?: FilterField<F>[];
    setFilters?: ActionCreatorWithPayload<F>;
    setColumns?: ActionCreatorWithPayload<C | null>;
    setSearch?: ActionCreatorWithPayload<string>;
    extraActions?: React.ReactNode[];
    columnsSettings?: C | null;
    filterHelp?: string | number;
    titleClassName?: string;
    lastVisited?: string | null;
    locator?: string;
    searchFields?: { [key in keyof T]?: string };
    uniqueId?: string;
    columns: TableColumn<T>[];
    header?: ReactNode;
  };

// eslint-disable-next-line max-len
export function Table<T extends object, F extends FilterType<F> = {}, C extends ColumnSettings<T> = {}>(props: TableProps<T, F, C>) {
  const {
    action,
    setPageToStore,
    setPageSizeToStore,
    count,
    options,
    data,
    route,
    routeId,
    title,
    isLoadable,
    transNamespace,
    filterFields,
    setFilters,
    setColumns,
    setSearch,
    columns,
    isLoading,
    actions = [],
    extraActions = [],
    columnsSettings = null,
    filterHelp,
    titleClassName,
    lastVisited,
    locator,
    searchFields,
    uniqueId,
    header,
    ...restProps
  } = props;

  const items = data as T[];
  const { t } = useTranslation('common');

  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam('pageSize', withDefault(NumberParam, 10));
  const [search, setQuerySearch] = useQueryParam('search', StringParam);
  const history = useHistory();
  const classes = makeStyles(styles)();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isColumnsOpened, setIsColumnsOpened] = useState(false);
  const [isFilterDialogOpened, setIsFilterDialogOpened] = useState(false);
  const [isSearchOpened, setIsSearchOpened] = useState(!!search);
  const [queryFilters, setQueryFilters] = useFilterQueryParams(filterFields ?? []);

  const filterFieldsHash: { [key: string]: FilterField<F> } = useMemo(() => (filterFields || []).reduce((acc, cur) => (
    { ...acc, [cur.filter]: cur }
  ), {}), [filterFields]);

  const notEmptyFilters = useMemo(() => Object.entries(queryFilters).reduce<F>((acc, cur) => {
    const [key, value] = cur;
    if (!value || (value instanceof Array && value.length === 0)) {
      return acc;
    }

    const filterField = filterFieldsHash[key];
    if (isEqual(filterField.default, value)) {
      return acc;
    }

    return { ...acc, [key]: value };
  }, {} as F), [queryFilters]);

  const filtersCount = useMemo(() => Object.values(notEmptyFilters).length, [notEmptyFilters]);

  const customColumns = useMemo(() => columns
    .map(column => {
      const newColumn = columnsSettings && column.field && columnsSettings[column.field]
        ? update(column, { hidden: { $set: (columnsSettings[column.field] as EntityColumn).hidden } })
        : column;
      const filterField = filterFields?.filter(field => field.field === newColumn.field);

      if (!filterField
        || !filterField?.length
        || !Object.keys(notEmptyFilters).some(filter => filterField.findIndex(f => f.filter === filter) !== -1)
      ) {
        return newColumn;
      }

      return {
        ...newColumn,
        headerStyle: {
          ...newColumn.headerStyle,
          boxShadow: 'inset 0 -8px 0 8px #FFFFFF, inset 0px 3px 0 0px #58C3FF',
        },
      };
    })
    .sort((a, b) => {
      if (!columnsSettings || !a.field || !b.field || !columnsSettings[a.field] || !columnsSettings[b.field]) return 0;
      return (columnsSettings[a.field] as EntityColumn).order - (columnsSettings[b.field] as EntityColumn).order;
    }),
  [notEmptyFilters, filterFields, columns, columnsSettings]);

  const { rowStyle, ...restOptions } = options || {};

  useEffect(() => {
    if (!setFilters) return;
    dispatch(setFilters(queryFilters));
  }, [queryFilters, setFilters, dispatch]);

  useEffect(() => {
    if (!setSearch) return;
    dispatch(setSearch(search || ''));
  }, [search, setSearch, dispatch]);

  useEffect(() => {
    if (action) {
      if (setPageToStore) {
        dispatch(setPageToStore(page));
      }

      if (setPageSizeToStore) {
        dispatch(setPageSizeToStore(pageSize));
      }

      dispatch(action({
        paging: { page: page - 1, pageSize },
        filter: queryFilters,
        searchPhrase: search && search.length >= MIN_SEARCH_STR_LENGTH ? search : '',
      }));

    }
  }, [page, pageSize, action, setPageToStore, setPageSizeToStore, queryFilters, search, dispatch]);

  useEffect(() => {
    const clickListener = (event: any) => { // todo: Property 'type' does not exist on type 'EventTarget'. Wut?
      if (event.target && event.target.type !== 'checkbox') {
        const headElem = (event.target as HTMLElement).closest('.MuiTableHead-root');
        if (headElem) {
          event.stopPropagation();
        }
      }
    };

    document.addEventListener('click', clickListener, true);

    return () => {
      document.removeEventListener('click', clickListener, true);
    };
  }, []);

  const handleSearchTextChange = useMemo(() => debounce(1000, (searchText?: string) => {
    if (!searchText || searchText.length >= MIN_SEARCH_STR_LENGTH) {
      setQuerySearch(searchText || undefined);
      setPage(undefined, 'replaceIn');
    }
  }), []);

  const tableHeadersStyle: CSSProperties = {
    fontWeight: 700,
    borderBottom: 'none',
    padding: theme.spacing(1, 2),
    lineHeight: theme.typography.pxToRem(32),
    whiteSpace: 'nowrap',
    borderTop: '1px solid #c4c4c4',
    pointerEvents: 'none',
  };

  const memoItems = useMemo(() => items.map(item => ({ ...item })), [items]);
  const memoTitle = useMemo(() => (<Title>{title}</Title>), [title]);

  const memoOptions = useMemo(() => ({
    rowStyle: (data: any, index: number, level: number) => {
      let styles: CSSProperties;
      if (!rowStyle) {
        styles = {};
      } else {
        styles = typeof rowStyle === 'function' ? rowStyle(data, index, level) : rowStyle;
      }
      if (routeId && lastVisited && data[routeId] === lastVisited) {
        styles.backgroundColor = '#DBF1EE';
      }
      if (data.wasRead === false) {
        styles.backgroundColor = '#FAF6D9';
      }
      styles.verticalAlign = 'top';
      return styles;
    },
    draggable: false,
    pageSize,
    pageSizeOptions: [10, 20, 50],
    ...restOptions,
    headerStyle: (restOptions?.headerStyle) ? {
      ...tableHeadersStyle,
      ...restOptions.headerStyle,
    } : tableHeadersStyle,
    columnsButton: false,
  }), [props]);

  const memoLocalization = useMemo(() => ({
    body: {
      emptyDataSourceMessage: t('No records to display'),
    },
    toolbar: {
      searchTooltip: '',
      searchPlaceholder: t('Search'),
    },
    header: {
      actions: '',
    },
  }), [t]);
  const memoComponents = useMemo(() => ({
    Pagination: ({ rowsPerPage, rowsPerPageOptions, onChangePage, onChangeRowsPerPage }: any) => (
      (!count) ? null
        : (
          <Pagination
            count={count}
            page={page}
            pageSize={pageSize}
            loadable={isLoadable}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )
    ),
    Toolbar: () => null,
    Header: (props: any) => <MTableHeader {...props} />,
    FilterRow: () => null,
    Row: (props: any) => {
      const { data } = props;

      return (
        <MTableBodyRow
          {...props}
          data-locator={(uniqueId && locator) ? `${locator}_${get(data, uniqueId)}` : undefined}
        />
      );
    },
    Cell: (props: any) => {
      const { columnDef } = props;
      return (
        <MTableCell {...props} data-locator={columnDef?.locator} />
      );
    },
    Actions: (props: any) => {
      const { data } = props;

      return (
        <div data-locator={(uniqueId && locator) ? `${locator}_${get(data, uniqueId)}_actions` : undefined}>
          <MTableActions {...props} />
        </div>
      );
    },
  }), [props]);

  const onRowClickHandler = useCallback((ev, rowData) => {
    if (routeId) {
      history.push(`${route}/${rowData?.[routeId]}`);
    }
  }, [route, routeId]);

  const onChangeRowsPerPageHandler = useCallback((pageSize) => {
    setPageSize(pageSize === 10 ? undefined : pageSize);

    if ((page - 1) * pageSize > count) {
      const page = Math.ceil(count / pageSize);
      setPage(page);
    }
  }, [count, page]);

  const onChangePageHandler = useCallback((page) => {
    if (page === 0) return;
    setPage(page === 1 ? undefined : page);
  }, []);

  const handleSearchClick = useCallback(() => {
    setIsSearchOpened(true);
  }, []);

  const handleSearchClose = useCallback(() => {
    setIsSearchOpened(false);
  }, []);
  return (
    <>
      {options?.filtering && (
        <TableFilter<F>
          filters={queryFilters}
          setFilters={setQueryFilters}
          filterFields={filterFields || []}
          transNamespace={transNamespace}
          setPage={setPage}
          open={isFilterDialogOpened}
          closeFilters={() => setIsFilterDialogOpened(false)}
          helpId={filterHelp}
          locator={locator ? `${locator}_filter` : undefined}
        />
      )}
      {options?.columnsButton && (
        <TableColumns<T, C>
          open={isColumnsOpened}
          onClose={() => setIsColumnsOpened(false)}
          columns={customColumns}
          setColumns={setColumns}
          locator={locator ? `${locator}_columns` : undefined}
        />
      )}
      {((options?.toolbar) !== false) && (
        <Toolbar<T>
          title={title as string}
          className={titleClassName}
          actions={[
            {
              icon: () => <CogwheelIcon fill="#000000" />,
              isFreeAction: false,
              position: 'toolbar',
              onClick: () => setIsColumnsOpened(true),
              hidden: !options?.columnsButton,
              tooltip: t('Columns'),
              locator: locator ? `${locator}_button_columns` : undefined,
            },
            {
              icon: () => (
                <Badge
                  variant="dot"
                  color="primary"
                  classes={{ badge: classes.filtersBadge }}
                  badgeContent={filtersCount}
                >
                  <FilterIcon />
                </Badge>
              ),
              isFreeAction: false,
              position: 'toolbar',
              onClick: () => setIsFilterDialogOpened(true),
              hidden: !options?.filtering,
              tooltip: t('Filter'),
              locator: locator ? `${locator}_button_filter` : undefined,
            },
            {
              icon: () => (
                <Search
                  open={isSearchOpened}
                  disabled={!!isLoading}
                  onClick={handleSearchClick}
                  onClose={handleSearchClose}
                  onSearchChange={handleSearchTextChange}
                  maxLength={MAX_SEARCH_STR_LENGTH}
                  initialSearchText={search}
                  locator={locator}
                  searchFields={searchFields}
                  placeholder={t('searchPlaceholder')}
                />
              ),
              isFreeAction: true,
              position: 'toolbar',
              onClick: () => { },
              hidden: !options?.search,
            },
            ...actions,
          ]}
          extraActions={extraActions}
        />
      )}
      <div className={classes.root}>
        {
          header !== undefined
            ? (<div className={classes.header}>{header}</div>)
            : null
        }
        <MaterialTable
          icons={tableIcons}
          title={memoTitle}
          data={memoItems}
          columns={customColumns}
          actions={actions}
          isLoading={isLoading}
          localization={memoLocalization}
          options={memoOptions}
          components={memoComponents}
          onChangePage={onChangePageHandler}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          onRowClick={onRowClickHandler}
          {...restProps}
        />
      </div>
    </>
  );
}
