import { takeLatest, put, call } from 'redux-saga/effects';
import { 
  downloadLicenseTenantReport,
  licensesRequested,
  licensesResolved,
  licensesRejected,
  requestLicenses,
} from './slice';
import { getLicensesList, downloadLicenseTenantStats } from './api';
import { LicenseItem } from './types';

function* requestLicensesSaga(params: any) {
  try {
    yield put(licensesRequested(params));
    const licenses: LicenseItem[] = yield call(getLicensesList, params);
    yield put(licensesResolved({ licenses }));
  } catch (err) {
    const message = err.message || err;
    yield put(licensesRejected(message));
  }
}

function* downloadLicensesTenantReportSaga() {
  yield call(downloadLicenseTenantStats);
}

export const licensesSaga = [
  takeLatest(requestLicenses, requestLicensesSaga),
  takeLatest(downloadLicenseTenantReport, downloadLicensesTenantReportSaga),
];
