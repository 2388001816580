import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CustomImage } from '../CustomImage';
import { useSelector } from 'store/hook';

export type DescriptionMarkdownProps = {
  classes?: Record<string, string>;
  data: string;
}
export const DescriptionMarkdown = (props: DescriptionMarkdownProps) => {
  const { classes, data } = props;
  const { clientId, secret } = useSelector(store => store.auth);
  const REGEX_HTTP_PROTOCOL = /^https?:\/\//i;
  const isUrlHttp = (url: string) => REGEX_HTTP_PROTOCOL.test(url);
  return <div className={`${classes?.description} markdown`}>
    {
      ReactHtmlParser(data, {
        transform: (node) => {
          if (node.type === 'tag' && node.name === 'img') {
            return (
              <CustomImage
                src={node.attribs?.src}
                alt={node.attribs?.alt}
                clientId={clientId}
                secret={secret}
              />
            );
          }

          if (node.type === 'tag' && node.name === 'a' && node.attribs && node.attribs.href
            && isUrlHttp(node?.children[0]?.data ?? '')) {
            node.name = 'span';
            node.attribs = {};
          }
          return undefined;
        },
      })
    }
  </div>
}