import { getLocale } from 'utils/i18n';

type TenantSetting = {
  tenantId: string;
  autoResponse: boolean;
  tenantName: string;
};

export type TenantSettings = TenantSetting[];

export const getCurrentRadioValue = (autoResponse?: boolean, tenantlyMode?: boolean): string => {
  if (autoResponse) {
    return 'enabled';
  }
  if (tenantlyMode) {
    return 'tenant auto-response';
  }
  return 'disabled';
};

export const isTenantsSelectionChanged = (
  current: Record<string, boolean | undefined> | undefined,
  initial: Record<string, boolean | undefined>,
): boolean => Object.keys({ ...current, ...initial }).some(key => current?.[key] !== initial[key]);

export const isAutoResponseChanged = (init?: boolean, curr?: boolean): boolean => init !== curr;
export const isTenantlyModeChanged = (init?: boolean, curr?: boolean): boolean => init !== curr;

export function mapTenantsToSelectionFlags(
  tenants: TenantSettings,
): Record<string, boolean> {
  return Object.fromEntries(tenants.map(({ tenantId, autoResponse }) => [tenantId, autoResponse]));
}

export function selectAllTenants(
  tenants: TenantSettings,
  isChecked: boolean,
): Record<string, boolean> {
  return Object.fromEntries(tenants.map(({ tenantId }) => [tenantId, isChecked]));
}

export const isEnabledForAllTenants = (value: string):
  boolean => value !== 'disabled' && value !== 'tenant auto-response';

export const isEnabledForSelectedTenants = (value: string):
  boolean => value === 'tenant auto-response';

export function parseAutoResponseStatus(value: string) {
  const autoResponse = isEnabledForAllTenants(value);
  const tenantlyMode = isEnabledForSelectedTenants(value);

  return { autoResponse, tenantlyMode };
}

export function translateRootTenantName(tenants?: TenantSettings) {
  if (getLocale() !== 'en-US') {
    return tenants;
  }

  return tenants?.map(tenant => {
    if (tenant.tenantId === '-') {
      return { ...tenant, tenantName: 'Root tenant' };
    }
    return tenant;
  });
}

