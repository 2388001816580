import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CacheState, BaseCachePayload, UpdateCachePayload } from './types';
import { pendingCacheItem, resolveCacheItem, rejectCacheItem } from './helpers';

const name = 'cache';
const cacheLifetime = 600000; // 10 mins

const initialState: CacheState = {
  texts: [],
  images: [],
};

const cacheSlice = createSlice({
  name,
  initialState,
  reducers: {
    remove(state, { payload: { id, store } }: PayloadAction<BaseCachePayload>) {
      state[store] = state[store].filter(e => e.id !== id);
    },
    makePending(state, { payload: { id, store } }: PayloadAction<BaseCachePayload>) {
      const item = pendingCacheItem({ id });
      state[store] = state[store].concat([item])
    },
    resolve(state, { payload: { id, value, store } }: PayloadAction<UpdateCachePayload>) {
      state[store] = state[store].map(e => {
        if (e.id === id) {
          return resolveCacheItem({ expiresDuration: cacheLifetime, id, value });
        };
        return e;
      });
    },
    reject(state, { payload: { id, value, store } }: PayloadAction<UpdateCachePayload>) {
      state[store] = state[store].map(e => {
        if (e.id === id) {
          return rejectCacheItem({ id, value });
        };
        return e;
      });
    },
    add(state, { payload: { id, value, store } }: PayloadAction<UpdateCachePayload>) {
      const item = resolveCacheItem({ expiresDuration: cacheLifetime, id, value });
      state[store] = state[store].concat([item])
    }
  },
});

export const {
  makePending: makePendingCache, reject: rejectCache,
  resolve: resolveCache, add: addCache, remove: removeCache,
} = cacheSlice.actions;

export default cacheSlice.reducer;
