import Select from 'react-select';
import React, { Dispatch, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { ActionMetaExtra, KLMultiSelectValue } from './types';
import { useClearIndicator, useMultiValue, useDropdownIndicator, useOption, useValueContainer } from './components';
import { customStyles, getValue, onChangeChecked } from './helpers';

const useStyles = makeStyles(styles);

export type SelectCheckedProps = {
  selectAllLabel?: string;
  value: KLMultiSelectValue[];
  options: KLMultiSelectValue[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  load?: (searchPhrase: string) => void;
  loading?: boolean;
  isIdle?: boolean;
  disabled?: boolean;
}

export const SelectChecked = (props: SelectCheckedProps) => {
  const { t } = useTranslation('common');
  const { selectAllLabel, value, options,
    onChange, placeholder = t('Select'), menuPlacement, disabled,
    isIdle, load, loading } = props;
  const allSelected = value.length > 0 && value.length === options.length;
  const classes = useStyles();
  const ref = useRef<Select | null>(null);

  const selectAllOption = ({
    label: t('Select all'),
    value: '*',
  });
  const checkedValues = allSelected ? [selectAllOption, ...value] : value;
  const checkedOptions = options.length > 0 ? [selectAllOption, ...options] : options;

  const onMenuAyncOpen = useCallback(() => {
    if (!isIdle) return;
    if (load) {
      load('');
    }
  }, [isIdle, load]);

  const handler = onChangeChecked({
    value: value.map(getValue),
    options: options.map(getValue),
    onChange,
    selectAllValue: selectAllOption.value,
  });

  const MultiValue = useMultiValue({
    selectAllValue: selectAllOption.value,
    selectAllLabel,
  });
  const ClearIndicator = useClearIndicator();
  const DropdownIndicator = useDropdownIndicator();
  const ValueContainer = useValueContainer({ allSelected });
  const Option = useOption();

  return (
    <Select
      ref={ref}
      className={classes.root}
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValue,
        Option,
        ValueContainer,
      }}
      options={checkedOptions}
      value={checkedValues}
      onChange={(_, meta) => handler({ rawMeta: meta as ActionMetaExtra })}
      isClearable
      isMulti
      isSearchable={false}
      placeholder={placeholder}
      styles={customStyles}
      menuPlacement={menuPlacement}
      isDisabled={disabled}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      noOptionsMessage={() => t('No options')}
      openMenuOnClick
      openMenuOnFocus
      onMenuOpen={onMenuAyncOpen}
      isLoading={loading}
    />
  );
};
