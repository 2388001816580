import { postRequest, downloadFile } from 'utils/http';
import { LicenseItem, LicenseItemsRequest } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getLicensesList(req: LicenseItemsRequest): Promise<LicenseItem[]> {
  return ((await postRequest('licenses/list', {})) as any).licenses;
}

export function downloadLicenseTenantStats(): Promise<void> {
  return downloadFile('licenses/report', {});
}
