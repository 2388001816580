import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/Table';
import { deleteScheduleRequest, getReportSettingsRequest } from 'services/clientInformer/slice';
import { useDispatch } from 'react-redux';
import { KLButton } from 'components/KLButton';
import { AddIcon } from 'assets/icons/AddIcon';
import { SideDialog } from 'components/SideDialog';
import theme from 'themes/default';
import { useSelector } from 'store/hook';
import { ReportSettings, ReportSettingsRecord } from 'services/clientInformer/types';
import { listRequest } from 'services/tenants/slice';
import { capitalize, makeStyles } from '@material-ui/core';
import { useConfirmation } from 'components/Confirmation';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import { DeleteScheduleConfirmation } from '../DeleteScheduleConfirmation';
import { ReportSettingsForm } from './ReportSettingsForm';
import { extractWeekdayFromCron, WEEKDAYS } from './utils';
import styles from './styles';
import { EmailSpoiler } from './emailspoiler';

const useStyles = makeStyles(styles);

interface Validators {
  [key: string]: {
    valid: boolean;
    touched: boolean;
  };
}

export const ReportSettingsTable: React.FC = () => {
  const { t } = useTranslation(['SettingsPage']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportSettingsRequest());
    dispatch(listRequest({}));
  }, []);

  const tenants = useSelector(({ tenants }) => tenants.list);

  const hasAddSchedulePermission = true;

  const [sideDialogData, setSideDialogData] = useState<any>();

  const onAddSchedule = useCallback(() => {
    setSideDialogData({});
  }, []);

  const onDialogCloseHandler = useCallback(() => {
    setSideDialogData(undefined);
  }, []);

  const { settingsReceived } = useSelector(state => state.clientInformer);

  const tenantNames = useMemo(
    () => Object.fromEntries(tenants.map(({ tenantId, name }) => [tenantId, name])),
    [tenants],
  );

  const onRowClickHandler = (event?: React.MouseEvent, rowData?: ReportSettingsRecord): void => {
    if (rowData) {
      setSideDialogData(rowData.clientReportSchedule);
    }
  };

  const classes = useStyles();

  const { openConfirmation, closeConfirmation } = useConfirmation();

  const clientId = useSelector(({ auth }) => auth.clientId);

  const onDeleteSchedule = (event: any, data: any) => {
    openConfirmation({
      title: t('ReportSettings.Confirm schedule deletion'),
      content: <DeleteScheduleConfirmation {...({ name: data.clientReportSchedule.description })} />,
      confirmText: t('Delete'),
      dismissText: t('Cancel'),
      onConfirm: () => {
        dispatch(
          deleteScheduleRequest({
            client: clientId,
            message: t('ReportSettings.Schedule deleted successfully'),
            type: data.clientReportSchedule.type,
          }),
        );
        closeConfirmation();
      },
    });
  };

  return (
    <>
      <Table
        actions={hasAddSchedulePermission ? [
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: t('ReportSettings.Delete schedule'),
            position: 'row',
            onClick: onDeleteSchedule,
          },
        ] : []}
        extraActions={hasAddSchedulePermission ? [
          <KLButton
            color="primary"
            variant="contained"
            startIcon={<AddIcon fill="#FFFFFF" />}
            onClick={onAddSchedule}
            id="user-list_button_add"
          >
            {t('ReportSettings.AddSchedule')}
          </KLButton>,
        ] : []}
        columns={[
          {
            searchable: false,
            sorting: false,
            width: `${theme.spacing(4)}px`,
          },
          {
            title: t('ReportSettings.TableColumnScheduleName'),
            sorting: false,
            render: (item) => item.clientReportSchedule.description,
            width: '20%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            title: t('ReportSettings.TableColumnTenantName'),
            sorting: false,
            render: (item) => {
              if (item.clientReportSchedule.tenantId === clientId) {
                return t('ReportSettings.TableColumnTenantNameValueRoot');
              }
              if (!item.clientReportSchedule.tenantId) {
                return t('ReportSettings.TableColumnTenantNameValueAll');
              }
              return tenantNames[item.clientReportSchedule.tenantId];
            },
            width: '20%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            title: t('ReportSettings.TableColumnWeekDay'),
            sorting: false,
            render: (item) => t(
              `ReportSettings.${capitalize(
                WEEKDAYS[extractWeekdayFromCron(item.clientReportSchedule.schedule) as keyof typeof WEEKDAYS],
              )
              }`,
            ),
            width: '20%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            title: t('ReportSettings.TableColumnEmails'),
            sorting: false,
            render: (item) => <EmailSpoiler emails={item.clientReportSchedule.emails} />,
            width: '20%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            title: t('ReportSettings.TableColumnStatus'),
            sorting: false,
            render: (item) => (
              item.clientReportSchedule.enabled
                ? t('ReportSettings.Status enabled')
                : t('ReportSettings.Status disabled')
            ),
            width: '20%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
        ]}
        data={settingsReceived}
        title={t('ReportSettings.TableName')}
        count={0}
        options={{
          showTextRowsSelected: false,
          paging: false,
          actionsColumnIndex: -1,
          tableLayout: 'auto',
        }}
        onRowClick={onRowClickHandler}
      />
      <SideDialog<ReportSettings | {}>
        state={{}}
        open={!!sideDialogData}
        title={
          sideDialogData
            && Object.entries(sideDialogData).length
            ? t('ReportSettings.Edit schedule')
            : t('ReportSettings.Create schedule')
        }
        onClose={onDialogCloseHandler}
        fullContentSpace
        render={() => (
          <ReportSettingsForm
            schedule={sideDialogData}
            onClose={onDialogCloseHandler}
          />
        )}
      />
    </>
  );
};
