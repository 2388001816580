import React, { useMemo, useState } from 'react';
import {
  ArrayParam,
  decodeDelimitedArray,
  DecodedValueMap,
  QueryParamConfig,
  QueryParamConfigMap,
  SetQuery,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Collapse, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KLCheckBox } from 'components/KLCheckBox';
import { KLDate, KLDateChangeHandler, KLDatePicker, KLDateType } from 'components/KLDatePicker';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import {
  FilterCheckedField,
  FilterDateField,
  FilterField,
  FilterProps,
  FilterSelectedField,
  FilterType,
} from './types';
import styles from './styles';
import { FilterSelected } from './FilterSelected';

const useStyles = makeStyles(styles);

export function getParamConfigMap<T>(fields: FilterField<T>[]): QueryParamConfigMap {
  const KLDateParam: QueryParamConfig<KLDate | undefined> = ({
    encode: (date: KLDate | null | undefined): string | undefined => {
      if (!date) return undefined;

      const { type, start, end } = date;
      return `${type}${start ? `_${start}` : ''}${end ? `_${end}` : ''}`;
    },
    decode: (query): KLDate | undefined => {
      const value = decodeDelimitedArray(query);

      if (!value) return undefined;

      const [type, start, end] = value;

      if (type && !(type in KLDateType)) return undefined;

      return { type: type as KLDateType || KLDateType.all, start: Number(start) || null, end: Number(end) || null };
    },
  });

  return fields.reduce<QueryParamConfigMap>((map, field) => {
    if (field.type === 'checked' || field.type === 'selected') {
      return { ...map, [field.filter]: withDefault(ArrayParam, field.default ? field.default : []) };
    }

    if (field.type === 'date') {
      return { ...map, [field.filter]: withDefault(KLDateParam, field.default ? field.default : undefined) };
    }

    return map;
  }, {});
}

export function useFilterQueryParams<T>(fields: FilterField<T>[]): [DecodedValueMap<any>, SetQuery<any>] {
  const queryParams = useMemo(() => getParamConfigMap<T>(fields), [fields]);
  const [query, setQuery] = useQueryParams(queryParams);
  return [query, setQuery];
}

export function Filter<T extends FilterType<T>>(props: FilterProps<T>) {
  const { fields, filters, setFilters, transNamespace = '', locator } = props;

  let trNamespaces: string[] = [];
  if (transNamespace) {
    trNamespaces.push(transNamespace);
  }
  const trFieldNamespaces: string[] = fields.reduce((acc: string[], field: any) => (
    field.transNamespace
      ? [...acc, field.transNamespace]
      : acc
  ), []);
  trNamespaces = trNamespaces.concat(trFieldNamespaces, 'filters');

  // todo: we have a problem here. We cant use tr keys for more the one namespace coz we need to use namespace prefixes
  // https://react.i18next.com/latest/usetranslation-hook#loading-namespaces
  const { t } = useTranslation(trNamespaces);
  const classes = useStyles();

  type CollapsedList = { [key: string]: boolean };
  const defaultCollapsedList: CollapsedList = fields.reduce<CollapsedList>((acc, field) => ({
    ...acc, [field.filter]: !!field.collapsed,
  }), {});
  const [collapsedList, setCollapsedList] = useState<CollapsedList>(defaultCollapsedList);

  function isChecked(field: FilterField<T>): field is FilterCheckedField<T> {
    return field.type === 'checked';
  }

  function isDate(field: FilterField<T>): field is FilterDateField<T> {
    return field.type === 'date';
  }

  function isSelected(field: FilterField<T>): field is FilterSelectedField<T> {
    return field.type === 'selected';
  }

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    const filter = [...filters[name as keyof T] as string[]];

    if (checked) {
      filter.push(value);
    } else {
      filter.splice(filter.indexOf(value), 1);
    }

    setFilters({ ...filters, [name]: filter });
  };

  const handleDateChange = (field: FilterDateField<T>): KLDateChangeHandler => (date) => {
    setFilters({
      ...filters,
      [field.filter]: date ? {
        type: date.type,
        start: date.start,
        end: date.end,
      } : undefined,
    });
  };

  const handleLegendClick = (field: FilterField<T>) => () => {
    setCollapsedList({ ...collapsedList, [field.filter]: !collapsedList[field.filter] });
  };

  return (
    <div className={classes.root}>
      {fields.filter(field => !field.hidden).map((field, idx) => (
        <FormControl key={idx} component="fieldset" className={classes.fieldset}>
          <Typography component="legend" variant="h4" className={classes.legend} onClick={handleLegendClick(field)}>
            <ArrowIcon
              classes={{ root: classes.legendIcon }}
              direction={collapsedList[field.filter] ? 'bottom' : 'right'}
              fill="#999999"
            />
            {t(field.title)}{field.hint && field.hint}
          </Typography>
          <FormGroup>
            <Collapse
              in={!collapsedList[field.filter]}
              classes={{ container: classes.collapse, wrapperInner: classes.group }}
            >
              {isChecked(field) && Object.entries(field.items).map(([idx, item]) => (
                <FormControlLabel
                  key={idx}
                  classes={{ label: classes.label }}
                  control={(
                    <KLCheckBox
                      color="primary"
                      name={(field as FilterCheckedField<T>).filter}
                      value={isChecked(field) && field.index ? idx : item}
                      checked={isChecked(field)
                        && ((filters[field.filter]) as string[]).includes(field.index ? idx : item)}
                      onChange={handleCheckedChange}
                      id={locator ? `${locator}_${field.filter}_${idx}` : undefined}
                    />
                  )}
                  label={t([item, item.toUpperCase(), Number.isNaN(Number(idx)) ? idx : item])}
                />
              ))}
              {isDate(field) && (
                <KLDatePicker
                  className={classes.date}
                  type={(filters[field.filter] as KLDate)?.type || KLDateType.all}
                  start={(filters[field.filter] as KLDate)?.start || null}
                  end={(filters[field.filter] as KLDate)?.end || null}
                  onChange={handleDateChange(field)}
                  id={locator ? `${locator}_${field.filter}` : undefined}
                />
              )}
              {isSelected(field) && <FilterSelected field={field} filters={filters} setFilters={setFilters} t={t} />}
            </Collapse>
          </FormGroup>
        </FormControl>
      ))}
    </div>
  );
}

export * from './types';
