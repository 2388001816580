import React, { ComponentType } from 'react';
import { components, ValueContainerProps } from 'react-select';
import { KLMultiSelectValue } from '../types';

type ValueContainerParams = {
  allSelected?: boolean;
}
export const useValueContainer = (params: ValueContainerParams):
  ComponentType<ValueContainerProps<KLMultiSelectValue>> => props => {
  const { children, getValue } = props;
  const { allSelected = false } = params;
  const currentValues = getValue();
  let toBeRendered = children;
  if (currentValues && children) {
    if (allSelected) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      toBeRendered = [[children[0][0]], children[1]];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
    }
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};
