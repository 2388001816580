import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  deleteIcon: {
    fill: '#000000',
    opacity: 0,
    '.MuiTableRow-root:hover &': {
      opacity: 1,
    },
  },
  docText: {
    fontWeight: 500,
  },
  docLink: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
});
