import { KLMultiSelectValue } from '../types';

export const createOption = (label: string | KLMultiSelectValue): KLMultiSelectValue => {
  if (typeof label === 'string') {
    return {
      label,
      value: label,
    };
  }
  return label;
};
