import React, { Dispatch } from 'react';
import { IndicatorComponentType } from 'react-select';
import { makeStyles } from '@material-ui/core';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { KLMultiSelectValue } from '../types';
import styles from '../styles';

const useStyles = makeStyles(styles);

export const useDropdownIndicator = (): IndicatorComponentType<KLMultiSelectValue> => {
  const classes = useStyles();
  return () => {
    return (
      <div
        className={classes.icon}
      >
        <ArrowIcon fill="#979797" direction="bottom" />
      </div>
    );
  };
};
