import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Grid,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { KLInput } from 'components/KLInput';
import { KLButton } from 'components/KLButton';
import { KLDatePicker, KLDateType, KLDate, KLDateValue } from 'components/KLDatePicker';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { usePermissions } from 'components/Permissions/hooks';
import { fixTimezoneOffset } from 'utils/date';
import {
  createRequest,
  configRequest,
  detailsSuccess,
} from 'services/tenants/slice';
import { InfoIcon } from 'assets/icons/InfoIcon';
import theme from 'themes/default';
import { getLocale } from 'utils/i18n';
import { GuideEntry } from '../../../GuidePage/components/GuideEntry';
import styles from './styles';
import { canSubmitForm, getDefaultEndDate, isRootTenant } from './helpers';

const useStyles = makeStyles(styles);

export const TenantForm = () => {
  const { details } = useSelector(state => state.tenants);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['SettingsPage', 'GuidePage']);
  const [editOrganizationUsersPerm] = usePermissions([
    'edit_organization_users',
  ]);

  const {
    isActive,
    name,
    description,
    currentAssetsCount,
    licenseStartDate,
    tenantId,
    blobId,
    licenseEndDate,
  } = details || {};

  const [active, setActive] = useState(isActive ?? true);
  const [tenantName, setTenantName] = useState(name || '');
  const [desc, setDesc] = useState(description || '');
  const [start] = useState<KLDateValue>(licenseStartDate || Date.now());
  const [end, setEnd] = useState(getDefaultEndDate(details?.rules?.maxEndDate, licenseEndDate));
  const currentDate = new Date();

  const handleSubmit = () => {
    dispatch(createRequest({
      isActive: active,
      tenantName,
      tenantDescription: desc,
      licenseStartDate: start,
      licenseEndDate: end,
      tenantId: details?.tenantId,
    }));
  };

  const canSubmit = canSubmitForm({
    tenantName,
    start,
    end,
    tenantId,
  });

  const isConfigExpired = () => {
    if (!end) {
      return false;
    }
    const endDate = new Date(end);
    return endDate < currentDate;
  };

  const oneDayInMs = 24 * 60 * 60 * 1000;

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={2} alignItems="stretch" className={classes.formWrapper}>
        <Grid item>
          <FormControlLabel
            control={(
              <Switch
                checked={active}
                onChange={() => {
                  setActive(!active);
                }}
                name="acive"
                disabled={!editOrganizationUsersPerm || isRootTenant(tenantId)}
              />
            )}
            label={t('Active')}
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth className={classes.formBlock}>
            <FormLabel className={classes.label}>{t('Tenants.Tenant Name')} *</FormLabel>
            <KLInput
              id="tenants_add_name"
              noMarginTop
              value={tenantName}
              onChange={(event: ChangeEvent) => {
                const name = (event.target as HTMLInputElement).value;
                setTenantName(name);
              }}
              disabled={!editOrganizationUsersPerm || !!name || isRootTenant(tenantId)}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel className={classes.label}>{t('Description')}</FormLabel>
            <textarea
              className={classes.textarea}
              name="description"
              value={desc}
              disabled={!editOrganizationUsersPerm || isRootTenant(tenantId)}
              onChange={(event: ChangeEvent) => {
                const name = (event.target as HTMLInputElement).value;
                setDesc(name);
              }}
              id="tenants_add_description"
            />
          </FormControl>
        </Grid>
        {currentAssetsCount !== undefined
          ? (
            <Grid item>
              <FormControl fullWidth className={classes.formBlock}>
                <FormLabel className={classes.label}>{t('Tenants.Connected assets')}</FormLabel>
                <KLInput
                  disabled
                  noMarginTop
                  value={currentAssetsCount}
                  id="tenants_add_assets-number"
                />
              </FormControl>
            </Grid>
          ) : null}

        {!isRootTenant(tenantId)
          && (
          <Grid item>
            <FormControl fullWidth className={classes.formBlock}>
              <FormLabel className={classes.label}>
                <span>
                  {t('Tenants.MDR config lifetime')}
                </span>
                <Tooltip
                  title={t(
                    'Tenants.Tenant expiration must be before license expiration',
                    {
                      expirationDate: new Date((details?.rules?.maxEndDate ?? 0) + oneDayInMs)
                        .toLocaleDateString(getLocale(), {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }),
                    },
                  ) as string}
                >
                  <div>
                    <InfoIcon
                      fill={theme.palette.info.main}
                      className={classes.afterFieldTooltipInfoIcon}
                    />
                  </div>
                </Tooltip>
              </FormLabel>
              <KLDatePicker
                useTextInput
                fixedStartDate
                error={isConfigExpired()}
                disabled={!editOrganizationUsersPerm || isRootTenant(tenantId)}
                type={KLDateType.custom}
                start={fixTimezoneOffset(start || Date.now())}
                end={end ? fixTimezoneOffset(end) : null}
                onChange={(date?: KLDate) => {
                  if (date) {
                    const { end } = date;
                    setEnd(end);
                  }
                }}
                minDate={fixTimezoneOffset(Date.now())}
                maxDate={fixTimezoneOffset(details?.rules?.maxEndDate || Date.now())}
                id="tenants_add_lifetime"
              />
              {isConfigExpired()
                ? (
                  <Typography color="error" variant="caption">
                    {t('Tenants.The configuration file has expired')}
                  </Typography>
                ) : null}
            </FormControl>
          </Grid>
          )}
        {
          details?.blobId && editOrganizationUsersPerm ? (
            <Grid item>
              <div className={classes.buttonsNotification}>
                <WarningIcon className={classes.notificationHeader} />
                <div className={classes.notificationText}>
                  <GuideEntry
                    text={'Tenants.notificationWarning'}
                    link={'Tenants.notificationWarningLink'}
                    linkName={'Tenants.notificationWarningLinkName'}
                    ns={'SettingsPage'}
                  />
                </div>
              </div>
              <div className={classes.buttons}>
                <DownloadIcon />
                <KLButton
                  type="submit" color="primary"
                  variant="text"
                  disabled={!canSubmit}
                  onClick={() => dispatch(configRequest({
                    tenantId,
                    blobId,
                    purpose: 0,
                  }))}
                  id="tenants_edit_download-kea"
                >
                  {'MDR configuration file.zip'}
                </KLButton>
                <Typography component="span">{` (${t('GuidePage:blob included')})`}</Typography>
              </div>
            </Grid>
          ) : null
        }

        <div className={classes.footer}>
          {editOrganizationUsersPerm ? (
            <KLButton
              type="submit" color="primary"
              variant="contained"
              disabled={!canSubmit}
              onClick={handleSubmit}
              id="tenants_button_submit"
            >{t('Save')}
            </KLButton>
          ) : null}
          <KLButton
            color="primary"
            variant="outlined"
            onClick={() => dispatch(detailsSuccess(null))}
            id="tenants_button_cancel"
          >{t('Cancel')}
          </KLButton>
        </div>
      </Grid>
    </div>
  );
};
