import { Styles } from 'react-select';

export const customStyles: Styles = {
  control: (provided) => ({
    ...provided,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#B3B3B3',
    borderRadius: 3,
    minHeight: 32,
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05)',
    '&:hover': {
      borderColor: '#B3B3B3',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 0 1px 4px',
    fontSize: '14px',
    lineHeight: '16px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#B3B3B3',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    marginTop: 2,
    borderRadius: 0,
    zIndex: 10,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 14,
    lineHeight: '32px',
    padding: '0 8px',
    backgroundColor: 'transparent',
    fontWeight: 300,
    cursor: 'pointer',
    color: '#000000',
    '& label': {
      cursor: 'pointer',
    },
    '&:hover': {
      fontWeight: 600,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  }),
  input: (provided) => ({
    ...provided,
    flex: 1,
    zIndex: 1,
    '& div': {
      width: '100%',
    },
    '& input': {
      width: '100% !important',
    },
  }),
};
