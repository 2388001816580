import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import { Table } from 'components/Table';
import theme from 'themes/default';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { requestLicenses, setColumns, setFilters, downloadLicenseTenantReport } from 'services/licenses/slice';
import { LicenseItem, LICENSE_TYPES, PRODUCT_EDITIONS } from 'services/licenses/types';
import { DateTime } from 'components/DateTime';
import { LinkButton } from 'components/LinkButton';
import { ACTIVATION_PAGE } from 'global/routes';
import { useSelector } from 'store/hook';
import { GuidePage } from 'pages/GuidePage';
import { Loading } from 'components/Loading';
import { downloadMDRConfigRequest } from '../../../../services/activation/slice';
import { LicensesTableHeader } from './Header';
import styles from './styles';

const useStyles = makeStyles(styles);

function isExpiredLicense(license: LicenseItem): boolean {
  const expirationTimestamp = typeof license.expirationDate?.value?.seconds === 'string'
    ? parseInt(license.expirationDate.value.seconds, 10) * 1000
    : undefined;
  return expirationTimestamp !== undefined && expirationTimestamp < Date.now();
}

const TWO_WEEKS = 14 * 24 * 60 * 60 * 1000;

function isExpiringSoonLicense(license: LicenseItem): boolean {
  const expirationTimestamp = typeof license.expirationDate?.value?.seconds === 'string'
    ? parseInt(license.expirationDate.value.seconds, 10) * 1000
    : undefined;
  return expirationTimestamp !== undefined && expirationTimestamp > Date.now()
    && (expirationTimestamp - TWO_WEEKS) < Date.now();
}

function getTooltip(t: (s: string) => string, license: LicenseItem): string | undefined {
  if (isExpiringSoonLicense(license)) {
    return t('License expires soon');
  }
  if (isExpiredLicense(license)) {
    return t('License expired');
  }
  return undefined;
}

export function LicensesTable() {
  const { t } = useTranslation(['LicensesTable']);
  const classes = useStyles();
  const [tab, setTab] = useState<'active' | 'all'>('active');
  const dispatch = useDispatch();
  const isActiveLicensesTab = useMemo(() => tab === 'active', [tab]);
  const {
    licenses,
    isLoading,
    columns: columnSettings,
    filters,
  } = useSelector(({ licenses }: any) => (licenses));

  const { assetsLimit, assetsCount } = useSelector(({ activation }) => activation);

  const rows = useMemo(
    () => {
      if (isActiveLicensesTab) {
        return licenses
          .filter((license: LicenseItem) => (isActiveLicensesTab ? license.isActive : true));
      }

      return licenses
        .filter((license: LicenseItem) => {

          if (filters?.status?.includes('inactive') && !license.isActive) {
            return true;
          }

          if (filters.status === undefined || filters.status.length === 0) {
            return true;
          }

          if (filters?.status?.includes('active') && license.isActive) {
            return true;
          }

          if (filters?.status?.includes('deleted') && license.isDeleted) {
            return true;
          }

          if (filters?.status?.includes('blocked') && license.licenseBlocked) {
            return true;
          }

          if (filters?.status?.includes('expired')) {
            return isExpiredLicense(license);
          }

          return false;
        });
    },
    [licenses, isActiveLicensesTab, filters],
  );

  const onDownloadMdrTenantReport = useCallback(
    () => dispatch(downloadLicenseTenantReport()),
    [],
  );

  const region: string | undefined = useMemo(
    () => (licenses.find(({ isActive }: LicenseItem) => isActive) || licenses[0])?.region,
    [licenses],
  );

  const edition: string | undefined = useMemo(
    () => PRODUCT_EDITIONS[(licenses.find(({ isActive }: LicenseItem) => isActive) || licenses[0])?.productEdition],
    [licenses],
  );

  const activeLicensesCount = useMemo(
    () => licenses.filter(({ isActive }: LicenseItem) => isActive).length,
    [licenses],
  );

  const downloadConfig = useCallback((license: LicenseItem) => () => {
    dispatch(downloadMDRConfigRequest(license.licenseId as any));
  }, []);

  const filterFields = useMemo(
    () => [
      {
        type: 'checked' as const,
        title: t('ColumnNames.Status'),
        filter: 'status',
        items: {
          [t('LicenseStatuses.Active')]: 'active',
          [t('LicenseStatuses.Inactive')]: 'inactive',
          [t('LicenseStatuses.Blocked')]: 'blocked',
          [t('LicenseStatuses.Deleted')]: 'deleted',
          [t('LicenseStatuses.Expired')]: 'expired',
        },
        collapsed: false,
        default: ['active', 'inactive', 'blocked', 'deleted'],
        hidden: false,
      },
    ], [],
  );

  useEffect(
    () => {
      dispatch(requestLicenses({}));
    },
    [],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (licenses.length === 0 && !isLoading) {
    return (
      <GuidePage />
    );
  }

  return (
    <Grid item xs={12}>
      <Table
        extraActions={[
          activeLicensesCount > 1
            ? (
              <span className={classes.docText}>
                <Trans
                  defaults="LicensesTable:MDR license management is available in MDR Plugin"
                  values={{ link: t('MDR plugin licenses link text') }}
                  components={[
                    (
                      <a
                        className={classes.docLink}
                        href={t('MDR plugin licenses link href')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        placeholder
                      </a>
                    ),
                  ]}
                />
              </span>
            )
            : (
              <LinkButton
                className={classes.button}
                variant="outlined"
                color="primary"
                to={`${ACTIVATION_PAGE}/new`}
              >
                {t('Enter a new activation code')}
              </LinkButton>
            ),
        ]}
        header={(
          <LicensesTableHeader
            licensesCount={licenses.length}
            region={region}
            solution={edition}
            assetsCount={assetsCount ?? undefined}
            assetsLimit={assetsLimit ?? undefined}
            tab={tab}
            onTabChange={setTab}
            onDownloadReport={onDownloadMdrTenantReport}
          />
        )}
        actions={[]}
        columns={[
          {
            searchable: false,
            sorting: false,
            width: `${theme.spacing(4)}px`,
          },
          {
            field: 'type',
            title: t('ColumnNames.License type'),
            sorting: false,
            render: (license: LicenseItem) => t(`LicenseTypes.${LICENSE_TYPES[license.licenseType]}`),
            width: '10%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            field: 'license',
            title: t('ColumnNames.License'),
            sorting: false,
            render: (license: LicenseItem) => (
              <>
                {license.name}
                <div className={classes.licenseId}>
                  {license.activationLicenseId}
                </div>
              </>
            ),
            width: '40%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            field: 'assetsLimit',
            title: t('ColumnNames.Assets/Limit'),
            sorting: false,
            render: (license: LicenseItem) => (
              <span>
                <span className={
                  Number(license.currentAssetsCount) > Number(license.assetsCount)
                    ? classes.error
                    : undefined
                  }
                >
                  {license.currentAssetsCount}
                </span> / {license.assetsCount}
              </span>
            ),
            width: '10%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            field: 'activation',
            title: t('ColumnNames.Activation date'),
            sorting: false,
            render: (license: LicenseItem) => (
              <DateTime timestamp={parseInt(license.activationDate.seconds, 10) * 1000} />
            ),
            width: '15%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            field: 'Expiration',
            title: t('ColumnNames.Expiration date'),
            sorting: false,
            render: (license: LicenseItem) => {

              if (typeof license.expirationDate?.value?.seconds !== 'string') {
                return t('Expiration status');
              }

              const tooltip = getTooltip(t, license);
              if (tooltip) {
                return (
                  <Tooltip placement="top" title={tooltip ?? ''}>
                    <span className={tooltip ? classes.expirationHighlighted : undefined}>
                      <DateTime timestamp={parseInt(license.expirationDate.value.seconds, 10) * 1000} />
                    </span>
                  </Tooltip>
                );
              }
              return (
                <DateTime timestamp={parseInt(license.expirationDate.value.seconds, 10) * 1000} />
              );
            },
            width: '15%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            field: 'status',
            title: t('ColumnNames.Status'),
            sorting: false,
            render: (license: LicenseItem) => {
              if (license.licenseBlocked) {
                return t('LicenseStatuses.Blocked');
              }
              if (license.isDeleted) {
                return t('LicenseStatuses.Deleted');
              }
              if (isExpiredLicense(license)) {
                return t('LicenseStatuses.Expired');
              }
              return (license.isActive ? t('LicenseStatuses.Active') : t('LicenseStatuses.Inactive'));
            },
            width: '17%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            field: 'config',
            title: t('ColumnNames.DownloadArchieve'),
            sorting: false,
            render: (license: LicenseItem) => (
              license.isActive
                ? <a href="#" onClick={downloadConfig(license)} className={classes.configLink}>{t('ConfigLink')}</a>
                : null
            ),
            width: '15%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
        ]}
        isLoading={isLoading}
        data={rows}
        title={t('Licenses')}
        count={0}
        options={{
          showTextRowsSelected: false,
          paging: false,
          actionsColumnIndex: -1,
          tableLayout: 'auto',
          filtering: tab === 'all',
          columnsButton: true,
        }}
        setColumns={setColumns}
        setFilters={setFilters}
        columnsSettings={columnSettings}
        filterFields={filterFields}
      />

    </Grid>
  );
}
