import React from 'react';
import { useParams } from 'react-router-dom';
import { encodeQueryParams, QueryParamConfigMap, StringParam } from 'use-query-params';
import { stringifyUrl, ParsedQuery } from 'query-string';
import { List, ListProps, ListItemProps, ListItemIconProps, ListItemTextProps } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { IncidentsIcon } from 'assets/icons/IncidentsIcon';
import { ReactComponent as MonitoringIcon } from 'assets/icons/MonitoringIcon/icon.svg';
import { AssetsIcon } from 'assets/icons/AssetsIcon';
import { CogwheelIcon } from 'assets/icons/CogwheelIcon';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { ListItemLink } from 'components/ListItemLink';
import { getParamConfigMap, FilterField } from 'components/Filter';
import { filterFields as incidentFilterFields } from 'pages/IncidentListPage';
import { IncidentFilters } from 'services/incidents/types';
import { filterFields as assetFilterFields } from 'pages/AssetListPage';
import { AssetListFilters } from 'services/assets/types';
import { useSelector } from 'store/hook';
import { INCIDENT_LIST_PAGE, ASSET_LIST_PAGE, SETTINGS_PAGE, STATISTICS_PAGE, ABOUT_PAGE } from 'global/routes';
import { useTranslation } from 'react-i18next';
import { ShowOnPermission } from 'components/ShowOnPermission';

type MainMenuProps = {
  listProps?: ListProps;
  itemProps?: ListItemProps;
  itemIconProps?: Omit<ListItemIconProps, 'children'>;
  itemTextProps?: ListItemTextProps;
  dense: boolean;
};

function getPageURL<T>(
  url: string,
  page?: number,
  pageSize?: number,
  filters?: T,
  filterFields?: FilterField<T>[],
  search?: string,
) {
  type Query = { [K in keyof T]?: T[K] } & {
    page?: string;
    pageSize?: string;
    search?: string;
  }
  let query: Query = {};
  let queryParam: QueryParamConfigMap = {};

  if (page && page > 1) {
    query.page = `${page}`;
    queryParam.page = StringParam;
  }

  if (pageSize && pageSize > 10) {
    query.pageSize = `${pageSize}`;
    queryParam.pageSize = StringParam;
  }

  if (filters && filterFields) {
    query = { ...query, ...filters };
    queryParam = { ...queryParam, ...getParamConfigMap<T>(filterFields) };
  }

  if (search) {
    query.search = search;
    queryParam.search = StringParam;
  }

  // return stringifyUrl({ url, query });
  const encodedQuery = encodeQueryParams(queryParam, query);

  return stringifyUrl({ url, query: encodedQuery as ParsedQuery<string> });
}

export const MainMenu: React.FC<MainMenuProps> = (props) => {
  const { t } = useTranslation('MainMenu');
  const { itemProps, listProps, itemIconProps, itemTextProps, dense } = props;
  const { id } = useParams() as { id: string };
  const {
    page: incidentsPage,
    pageSize: incidentsPageSize,
    unreadCount: incidentUnreadCount,
    filters: incidentFilters,
    search: incidentSearch,
  } = useSelector(state => state.incidents);
  const {
    page: assetsPage,
    pageSize: assetsPageSize,
    filters: assetListFilters,
    search: assetListSearch,
  } = useSelector(state => state.assets);
  const theme = useTheme();

  const menuItems: {
    to: string;
    text: string;
    locator: string;
    icon?: React.ReactElement;
    count?: number;
    countColor?: string;
    countBackgroundColor?: string;
    permissions?: string[];
  }[] = [
    {
      to: getPageURL(STATISTICS_PAGE),
      text: t('Monitoring'),
      icon: <MonitoringIcon style={{ width: 16 }} />,
      permissions: ['view_organization_monitoring'],
      locator: 'link_monitoring',
    },
    {
      to: getPageURL<IncidentFilters>(
        INCIDENT_LIST_PAGE, incidentsPage, incidentsPageSize, incidentFilters, incidentFilterFields, incidentSearch,
      ),
      text: t('Incidents'),
      icon: <IncidentsIcon style={{ width: 16 }} />,
      count: incidentUnreadCount,
      countColor: '#333333',
      countBackgroundColor: theme.palette.warning.main,
      permissions: ['view_incidents_list'],
      locator: 'link_incidents',
    },
    {
      to: getPageURL<AssetListFilters>(
        ASSET_LIST_PAGE, assetsPage, assetsPageSize, assetListFilters, assetFilterFields, assetListSearch,
      ),
      text: t('Assets'),
      icon: <AssetsIcon />,
      permissions: ['view_assets_list'],
      locator: 'link_assets',
    },
    {
      to: getPageURL<AssetListFilters>(
        SETTINGS_PAGE, assetsPage, assetsPageSize, assetListFilters, assetFilterFields, assetListSearch,
      ),
      text: t('Settings'),
      icon: <CogwheelIcon fill="#FFFFFF" />,
      permissions: ['view_organization_settings', 'view_portal_settings'],
      locator: 'link_settings',
    },
    {
      to: getPageURL(ABOUT_PAGE),
      text: t('About'),
      icon: <InfoIcon fill="#FFFFFF" />,
      permissions: ['view_organization'],
      locator: 'link_account',
    },
  ];

  return (
    <List {...listProps}>
      {menuItems.map(({
        to,
        text,
        icon,
        count,
        countColor,
        countBackgroundColor,
        permissions,
        locator,
      }) => (
        <ShowOnPermission permissions={permissions || ['']} key={to}>
          <ListItemLink
            to={to}
            primary={text}
            icon={icon}
            selected={id ? to.includes(id) : false}
            listItemProps={{ ...itemProps, id: locator }}
            listItemIconProps={itemIconProps}
            listItemTextProps={itemTextProps}
            count={count}
            countColor={countColor}
            countBackgroundColor={countBackgroundColor}
            dense={dense}
          />
        </ShowOnPermission>
      ))}
    </List>
  );
};
