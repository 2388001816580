import useStyles from 'pages/StatisticsPage/utils/useStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hook';
import { DonutChart } from '../DonutChart';
import { CustomLegendStatus } from '../CustomLegends';

const ResponsesStats = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const {
    data: { responsesStatsByStatus = {} },
  } = useSelector((state) => state.statistics);

  return (
    <div className={classes.chartTile}>
      <h2 className={classes.subheader}>{t('Responses')}</h2>
      <DonutChart data={responsesStatsByStatus.data} options={responsesStatsByStatus.options}>
        <CustomLegendStatus />
      </DonutChart>
    </div>
  );
};

export default ResponsesStats;
