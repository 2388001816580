import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@material-ui/core';
import sub from 'date-fns/sub';
import getTime from 'date-fns/getTime';
import { Table, TableColumn } from 'components/Table';
import { DateTime } from 'components/DateTime';
import { FilterField } from 'components/Filter';
import {
  AssetState,
  columnsGetRequest,
  columnsSetRequest,
  listRequest,
  setColumns,
  setFilters,
  setPage,
  setPageSize,
  setSearch,
  setSuggestion as setAssetSuggestion,
  suggestionRequest as suggestionAssetRequest,
} from 'services/assets/slice';
import { sendAssetsByEmailRequest } from 'services/clientInformer/slice';
import {
  listRequest as listTenantRequest,
} from 'services/tenants/slice';
import {
  AssetListColumns,
  AssetListFilters,
  AssetListItem,
  AssetStatus,
  AssetSuggestion,
} from 'services/assets/types';
import { useSelector } from 'store/hook';
import { ASSET_LIST_PAGE } from 'global/routes';
import { ASSET_LIST_FILTER_HELP } from 'utils/help';
import { HIDE_ASSET_STATUSES_FILTER, IS_HIDE_ASSET_STATUSES } from 'global/environments';
import { getT } from 'utils/i18n';
import { usePermissions } from 'components/Permissions/hooks';
import { KLDateType } from 'components/KLDatePicker';
import { KLButton } from 'components/KLButton';
import { KLMultiSelectType } from 'components/KLMultiSelect';
import { AbsentIcon } from 'assets/icons/AbsentIcon';
import { IsolatedIcon } from 'assets/icons/IsolatedIcon';
import { OfflineIcon } from 'assets/icons/OfflineIcon';
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { InfoIcon } from 'assets/icons/InfoIcon';
import * as filtersConfig from 'configs/filters.json';
import { getGeneralConfigRequest } from 'services/settings/slice';
import { CriticalAssetIcon } from 'assets/icons/CriticalAssetIcon';
import { DetailsDialog } from './components/DetailsDialog';
import styles from './styles';

const StatusTooltip: React.FC = () => {

  const useStyles = makeStyles(styles);
  const theme = useTheme();
  const classes = useStyles();
  const { daysForStatusOffline } = useSelector(state => state.settings);
  const daysForStatusAbsent = 30;
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={(
        <>
          {['Ok', 'Warning', 'Critical', 'Offline', 'Absent'].map(status => (
            <p key={status}>
              <span className={classes.status}>
                {getT(`AssetListPage:${status}`)}
              </span>&nbsp;-&nbsp;
              <span className={classes.statusDescription}>
                {getT(
                  `AssetListPage:StatusDescription.${status}`,
                  ['Offline', 'Absent'].includes(status) ? { daysForStatusOffline, daysForStatusAbsent } : {},
                )}
              </span>
            </p>
          ))}
        </>
      )}
      interactive
      placement="right-start"
      leaveDelay={1000}
    >
      <span className={classes.span}>
        <InfoIcon className={classes.infoIcon} fill={theme.palette.info.contrastText} />
      </span>
    </Tooltip>
  );
};

export const filterFields: FilterField<AssetListFilters>[] = [
  {
    type: 'date',
    title: 'Last seen ago',
    filter: 'lastSeen',
    field: 'lastSeen',
    default: {
      type: KLDateType.month,
      start: getTime(sub(new Date(), { months: 1 })),
      end: null,
    },
  },
  {
    type: 'selected',
    selectType: KLMultiSelectType.async,
    title: 'Asset name',
    filter: 'hostNames',
    field: 'hostName',
    request: suggestionAssetRequest,
    setRequest: setAssetSuggestion,
    placeholder: 'filters:Start entering name',
    transNamespace: 'filters',
  },
  {
    type: 'selected',
    selectType: KLMultiSelectType.checked,
    title: 'Tenant',
    filter: 'tenantsNames',
    field: 'tenantName',
    request: listTenantRequest,
    valueToOption: (value) => {
      if (value !== 'Root tenant') return { value, label: value };
      return { value, label: getT('AssetListPage:Root tenant') };
    },
    transNamespace: 'filters',
  },
  {
    type: 'checked',
    title: 'Status',
    hint: <StatusTooltip />,
    filter: 'statuses',
    field: 'status',
    items: filtersConfig.assets.status,
    default: [
      String(AssetStatus.Ok),
      String(AssetStatus.Warning),
      String(AssetStatus.Critical),
    ],
    index: true,
    hidden: IS_HIDE_ASSET_STATUSES || HIDE_ASSET_STATUSES_FILTER,
  },
  {
    type: 'selected',
    title: 'Isolation',
    filter: 'isIsolated',
    field: 'status',
    options: [
      { label: getT('AssetListPage:Isolated'), value: '2' },
      { label: getT('AssetListPage:Not isolated'), value: '1' },
    ],
    valueToOption: value => ({
      label: value === '2' ? getT('AssetListPage:Isolated') : getT('AssetListPage:Not isolated'),
      value,
    }),
    menuPlacement: 'top',
    isMulti: false,
    transNamespace: 'AssetListPage',
  },
];

function getTooltipKeyAndParams(
  status: AssetStatus,
  daysForStatusOffline = 7,
  daysForStatusAbsent = 30,
): [string, any] {
  if (status === AssetStatus.Absent && daysForStatusAbsent > 3) {
    return ['AssetListPage:StatusDescription.AbsentDays', { daysForStatusAbsent }];
  }
  if (status === AssetStatus.Absent && daysForStatusAbsent <= 3) {
    return ['AssetListPage:StatusDescription.AbsentHours', { hoursForStatusAbsent: daysForStatusAbsent * 24 }];
  }
  if (status === AssetStatus.Offline) {
    return ['AssetListPage:StatusDescription.Offline', { daysForStatusOffline }];
  }
  return [`AssetListPage:StatusDescription.${AssetStatus[status]}`, { daysForStatusAbsent, daysForStatusOffline }];
}
export const showAssetStatus = (
  status?: AssetStatus,
  daysForStatusOffline?: number,
  daysForStatusAbsent?: number,
): React.ReactNode => {
  let icon: React.ReactElement;

  switch (status) {
    case AssetStatus.Ok:
      icon = <SuccessIcon outlined />;
      break;
    case AssetStatus.Warning:
      icon = <WarningIcon outlined />;
      break;
    case AssetStatus.Critical:
      icon = <CriticalAssetIcon />;
      break;
    case AssetStatus.Offline:
      icon = <OfflineIcon />;
      break;
    case AssetStatus.Absent:
      icon = <AbsentIcon />;
      break;
    default:
      return null;
  }

  return (
    <Tooltip
      title={getT(
        ...getTooltipKeyAndParams(status, daysForStatusOffline, daysForStatusAbsent),
      )}
      placement="right-start"
      arrow
    >
      {icon}
    </Tooltip>
  );
};

export const AssetListPage: React.FC = () => {
  const {
    list: items,
    count,
    isLoading,
    suggestion: assetSuggestion,
    suggestionLoading: assetSuggestionLoading,
    columns: columnsSettings,
    lastVisited,
  } = useSelector((state: { assets: AssetState }) => state.assets);
  const { isActiveOrganization, userId } = useSelector(state => state.auth);
  const { hideAbsentAssets, daysForStatusOffline } = useSelector(state => state.settings);
  const {
    isLoading: tenantLoading,
    list: tenantItems,
    listIdle: tenantIdle,
  } = useSelector((state: { tenants: any }) => state.tenants);

  const theme = useTheme();
  const { t } = useTranslation(['AssetListPage', 'filters']);
  const dispatch = useDispatch();
  const [
    filterPermission,
    searchPermission,
    exportAssetsPerm,
  ] = usePermissions([
    'filter_assets_list',
    'search_assets_list',
    'export_assets_list',
  ]);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [queryPage, setQueryPage] = useQueryParam('page', withDefault(NumberParam, 1));

  const onRowClickHandler = (event: any, rowData: any): void => {
    const { assetId } = rowData;
    setSelectedAssetId(assetId);
  };

  const onDialogCloseHandler = () => {
    setSelectedAssetId('');
  };

  const onMovePrevNextClickHandler = useCallback((id: string) => {
    setSelectedAssetId(id);
  }, []);

  const onMovePrevNextPageChangeHandler = useCallback((page?: number) => {
    setQueryPage(page || queryPage);
  }, []);

  const makeAssetSuggestion = ({ hostName }: AssetSuggestion) => hostName;
  const makeTenantSuggestion = ({ name }: any) => name;

  const customFilterFields = useMemo(() => filterFields.map(field => {
    switch (field.field) {
      case 'hostName':
        return {
          ...field,
          options:
            assetSuggestion?.map(makeAssetSuggestion),
          isRequesting: assetSuggestionLoading,
        };
      case 'tenantName':
        return {
          ...field,
          options:
            tenantItems?.map(makeTenantSuggestion),
          isRequesting: tenantLoading,
          isIdle: tenantIdle,
        };
      case 'status':
        if (field.type === 'checked') {
          return {
            ...field,
            items: Object.fromEntries(
              Object.entries(field.items)
                .filter((entry) => !(entry[1] === 'Absent' && hideAbsentAssets)),
            ),
          };
        }
        return field;
      default: return field;
    }
  }), [hideAbsentAssets, tenantIdle, tenantItems,
    tenantLoading, assetSuggestion, assetSuggestionLoading]);

  useEffect(() => {
    dispatch(getGeneralConfigRequest(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    dispatch(columnsGetRequest());
  }, [dispatch]);

  useEffect(() => {
    if (typeof columnsSettings === 'undefined') return;
    dispatch(columnsSetRequest(columnsSettings));
  }, [columnsSettings, dispatch]);

  const columns: TableColumn<AssetListItem>[] = [
    {
      searchable: false,
      sorting: false,
      width: `${theme.spacing(4)}px`,
    },
    {
      title: t('Status'),
      field: 'status',
      locator: 'status',
      render: ({ status, isolated, protectionIssues }) => (
        <Box component="span" display="flex" alignItems="center">
          {
            !IS_HIDE_ASSET_STATUSES
            && showAssetStatus(
              status,
              daysForStatusOffline,
              status === AssetStatus.Absent
                ? protectionIssues.reasons.find(({ code }) => code === 'NO_TELEMETRY_MORE_THAN_X_DAYS')?.args.days
                : undefined,
            )
          }
          {isolated && (
            <Tooltip
              title={t('Host is isolated') as string}
              placement="right-start"
              style={{ marginLeft: '8px' }}
              arrow
            >
              <IsolatedIcon />
            </Tooltip>
          )}
        </Box>
      ),
      sorting: false,
      hidden: IS_HIDE_ASSET_STATUSES,
      cellStyle: {
        lineHeight: 1,
        verticalAlign: 'middle',
      },
    },
    {
      title: t('Asset name'),
      field: 'hostName',
      sorting: false,
      hidden: false,
      locator: 'asset-name',
    },
    {
      title: t('Asset ID'),
      field: 'assetId',
      sorting: false,
      hidden: true,
      locator: 'asset-id',
    },
    {
      title: t('Domain'),
      field: 'domain',
      sorting: false,
      hidden: true,
      locator: 'domain',
    },
    {
      title: t('OS'),
      field: 'osVersion',
      sorting: false,
      hidden: true,
      locator: 'os',
    },
    {
      title: t('Application'),
      field: 'installedProductInfo',
      sorting: false,
      hidden: false,
      locator: 'application',
    },
    {
      title: t('Interfaces'),
      field: 'networkInterfaces',
      render: rowData => (rowData.networkInterfaces.length || t('No')),
      cellStyle: (data, rowData) => ({
        color: rowData.networkInterfaces.length ? 'inherit' : '#979797',
      }),
      sorting: false,
      hidden: false,
      locator: 'interfaces',
    },
    {
      title: t('Tenant'),
      field: 'tenantName',
      sorting: false,
      locator: 'tenant',
    },
    {
      title: t('Last seen ago'),
      field: 'lastSeen',
      type: 'datetime',
      defaultSort: 'desc',
      render: rowData => <DateTime timestamp={rowData.lastSeen} type="standard" withTime />,
      searchable: false,
      hidden: false,
      locator: 'last-seen',
    },
  ];

  return (
    <Grid item xs={12}>
      <DetailsDialog
        assetId={selectedAssetId}
        onClose={onDialogCloseHandler}
        open={selectedAssetId !== ''}
        onMovePrevNextClick={onMovePrevNextClickHandler}
        onMovePrevNextPageChange={onMovePrevNextPageChangeHandler}
      />
      <Table<AssetListItem, AssetListFilters, AssetListColumns>
        onRowClick={onRowClickHandler}
        action={listRequest}
        extraActions={exportAssetsPerm && isActiveOrganization ? [
          <KLButton
            color="primary"
            variant="text"
            onClick={() => {
              dispatch(sendAssetsByEmailRequest());
            }}
          >
            {t('Receive a CSV report')}
          </KLButton>,
        ] : []}
        setPageToStore={setPage}
        setPageSizeToStore={setPageSize}
        columns={columns}
        columnsSettings={columnsSettings || null}
        data={items}
        count={count}
        transNamespace="AssetListPage"
        filterFields={customFilterFields}
        filterHelp={ASSET_LIST_FILTER_HELP}
        setFilters={setFilters}
        setColumns={setColumns}
        setSearch={setSearch}
        route={ASSET_LIST_PAGE}
        title={t('Assets')}
        isLoading={isLoading}
        routeId={'assetId'}
        lastVisited={lastVisited || null}
        options={{
          search: searchPermission,
          filtering: filterPermission,
          columnsButton: true,
          rowStyle: ({ status }: AssetListItem) => ({
            color: [AssetStatus.Offline, AssetStatus.Absent].includes(status)
              ? fade('#000000', 0.5)
              : 'inherit',
          }),
        }}
        locator="assets"
        searchFields={{
          hostName: 'AssetListPage:Asset name',
          networkInterfaces: 'AssetListPage:Interfaces',
          domain: 'AssetListPage:Domain',
          osVersion: 'AssetListPage:OS',
          installedProductInfo: 'AssetListPage:Installed Kaspersky application',
        }}
        uniqueId="assetId"
      />
    </Grid>
  );
};
