import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { getNotificationsRequest, markAsReadRequest } from 'services/clientInformer/slice';
import { ClientNotification, NotificationType } from 'services/clientInformer/types';
import { getLang } from 'utils/i18n';
import { EntityType } from 'store/slice';
import styles from './styles';

const useStyles = makeStyles(styles);

type NotificationProps = {
  notification: ClientNotification;
};

const Notification = ({ notification }: NotificationProps) => {
  const { id, type, text, link } = notification;
  const [open, setOpen] = useState(true);
  const { t } = useTranslation('notifications');
  const classes = useStyles();
  const lang = getLang();
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    setOpen(false);
    dispatch(markAsReadRequest({ entityType: EntityType.NOTIFICATIONS, entityIds: [id] }));
  };

  return (
    <Collapse in={open}>
      <Alert
        severity={type}
        action={(
          <>
            <Button
              className={classes.understandButton}
              color="inherit"
              size="small"
              onClick={handleButtonClick}
            >
              {t('Review')}
            </Button>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </>
        )}
      >
        <span className={classes.text}>
          {text[lang]}
        </span>
        {link && (
          <a
            href={link.localized_href ? link.localized_href[lang] : link.href}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {link.text[lang]}
          </a>
        )}
      </Alert>
    </Collapse>
  );
};

const GlobalNotifications = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { notifications } = useSelector(state => state.clientInformer);
  const { organizations, isSocAnalyst } = useSelector(state => state.auth);

  useEffect(() => {
    if (organizations && organizations.length > 0 && !isSocAnalyst) {
      dispatch(getNotificationsRequest({ type: NotificationType.MDR, unreadOnly: true }));
    }
  }, [dispatch, organizations, isSocAnalyst]);

  return (
    <div className={classes.root}>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

export { GlobalNotifications };
