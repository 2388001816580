import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { CacheState } from './types';
import { addCache, makePendingCache, removeCache, rejectCache, resolveCache } from './slice';
import { getAttachmentFile } from 'services/attachments/api';
import { isExpiredItem } from './helpers';

export function markdown2html(md: string) {
  const encodedMd = Base64.encode(md);
  return axios.post('/markdown2html', { encodedMd })
    .then(response => response.data.data);
}

type Dispatch = ThunkDispatch<{
  cache: CacheState;
}, unknown, AnyAction>;
type FactoryMarkdownInfoProps<A extends { id: string }> = {
  key: keyof CacheState;
  request: (arg: A) => Promise<string>;
  extraCallback?: (arg: A, dispatch: Dispatch) => boolean | undefined;
}
export const factoryMarkdownInfo = <A extends { id: string }>
  (props: FactoryMarkdownInfoProps<A>) => {
  const { key, request, extraCallback } = props;
  return createAsyncThunk<void, A, { state: { cache: CacheState }, }>(
    `cache/${key}`,
    async (props, { getState, dispatch }) => {
      const { id } = props;

      const state = getState();
      const item = state.cache[key].find(e => e.id === id);
      if (item) {
        if (!isExpiredItem(item)) {
          return;
        }
        dispatch(removeCache({ id, store: key }));
      }
      if (extraCallback) {
        const shouldOver = extraCallback(props, dispatch);
        if (shouldOver) return;
      }
      dispatch(makePendingCache({ store: key, id }));
      try {
        const result = await request(props);
        dispatch(resolveCache({ id, value: result, store: key }));
      }
      catch (e) {
        dispatch(rejectCache({ id, value: String(e), store: key }));
      }
    }
  )
}

export const getMarkdown2html = factoryMarkdownInfo<{ id: string, value: string }>({
  key: 'texts',
  request: (props) => markdown2html(props.value),
  extraCallback: (props, dispatch) => {
    const { id, value } = props;
    if (value.match(/^[\d\w'"!:., ]+$/g)) {
      dispatch(addCache({ id, value, store: 'texts' }));
      return true;
    }
    return false;
  }
});


type ImageInfo = { clientId: string, secret: string, attachmentId: string }
export const geImageUrl = factoryMarkdownInfo<{ id: string, requestInfo: ImageInfo }>({
  key: 'images',
  request: async (props) => {
    const { attachmentId, clientId, secret } = props.requestInfo;
    const blob = await getAttachmentFile(clientId, secret, attachmentId);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onerror = reject;
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data);
      };
    });
  }
});