import React from 'react';
import { CustomImageLoaded } from './CustomImageLoaded';

export const CustomImage = (props: Partial<HTMLImageElement> & { clientId: string | null; secret: string }) => {
  const { clientId, secret, src, alt } = props;
  const parsedUrl = /attachment\/(.*)\/download/.exec(src ?? '');
  const attachmentId = parsedUrl && parsedUrl[1];
  if (!attachmentId || !clientId) {
    return null;
  };
  return <CustomImageLoaded alt={alt} attachmentId={attachmentId} clientId={clientId} secret={secret} />;
};
