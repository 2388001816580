import React, { useCallback, useMemo } from 'react';
import {
  KLMultiSelect,
  KLMultiSelectChangeHandler,
  KLMultiSelectType,
  KLMultiSelectValue,
} from 'components/KLMultiSelect';
import { TFunction } from 'i18next';
import { useDispatch } from 'react-redux';
import { FilterSelectedField, FilterType } from './types';

export type FilterSelectedProps<T> = {
  field: FilterSelectedField<T>;
  t: TFunction;
  filters: FilterType<T>;
  setFilters: (state: T) => void;
}
export function FilterSelected<T>(props: FilterSelectedProps<T>) {
  const { field: { options = [], ...field }, t, setFilters, filters } = props;
  const dispatch = useDispatch();
  const handleSelectedChange: KLMultiSelectChangeHandler = useCallback((value) => {
    const newFilters = {
      ...filters,
      [field.filter]: value,
    } as unknown as T;
    setFilters(newFilters);
  }, [field, setFilters, filters]);
  const getPlaceholder = (field: FilterSelectedField<T>) => {
    if (field.selectType === KLMultiSelectType.checked) {
      return t('common:Select');
    }
    if (!field.placeholder) {
      return t('filters:Search');
    }

    if (field.transNamespace) {
      return t(field.placeholder);
    }

    return field.placeholder;
  };

  const handleRequestSelected = useCallback((inputValue: string) => {
    if (!field.request) {
      return;
    }
    if (field.selectType === KLMultiSelectType.cached || field.selectType === KLMultiSelectType.checked) {
      dispatch(field.request(''));
      return;
    }

    if (!field.setRequest) {
      return;
    }
    if (inputValue) {
      dispatch(field.request(inputValue));
    } else {
      dispatch(field.setRequest([]));
    }
  }, [field]);

  const getLocaleLabel = (arg: string | KLMultiSelectValue): KLMultiSelectValue => {
    const transNamespace = field.transNamespace ?? 'common';
    if (typeof arg === 'string') {
      if (arg === 'Root tenant') {
        return { label: t('Root tenant'), value: arg }
      }

      const label = t(`${transNamespace}:${arg}`);
      return { label, value: label };
    }
    return { label: t(`${transNamespace}:${arg.label}`), value: arg.value };
  };

  const newOptions = useMemo(() => options.map(getLocaleLabel)
    .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())), [options, getLocaleLabel]);

  const newValues = (filters[field.filter] as string[] ?? [])
    .map(field.valueToOption ?? ((v) => {
      const item = newOptions.find(e => e.value === v);
      if (item) {
        return item;
      }
      return { label: v, value: v };
    }));

  return (
    <KLMultiSelect
      isIdle={field.isIdle}
      value={newValues}
      options={newOptions}
      onChange={handleSelectedChange}
      type={field.selectType}
      placeholder={getPlaceholder(field)}
      load={handleRequestSelected}
      loading={field.isRequesting}
      menuPlacement={field.menuPlacement}
      isMulti={field.isMulti}
    />
  );
}
