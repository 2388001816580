import React from 'react';
import { IndicatorComponentType } from 'react-select';
import { makeStyles } from '@material-ui/core';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { KLMultiSelectValue } from '../types';
import styles from '../styles';

const useStyles = makeStyles(styles);
export const useClearIndicator = (): IndicatorComponentType<KLMultiSelectValue> => {
  const classes = useStyles();
  return (props) => {
    const { clearValue } = props;
    return (
      <div
        className={classes.icon}
        onClick={clearValue}
        onMouseDown={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
      >
        <CloseIcon stroke="#979797" size={10} />
      </div>
    );
  };
};
