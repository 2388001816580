import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {},
  tabs: {
    display: 'flex',
  },
  tab: {
    display: 'block',
    padding: theme.spacing(1),
    borderBottom: '3px solid transparent',
    cursor: 'pointer',
  },
  activeTab: {
    borderColor: theme.palette.primary.dark,
    fontWeight: 900,
  },
  tooltip: {
    color: '#1D1D1B',
    backgroundColor: 'white',
    border: '1px solid #1D1D1B',
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  gap: {
    flexGrow: 1,
  },
  reportLinkWrap: {
    padding: theme.spacing(1),
    justifySelf: 'flex-end',
  },
  reportLink: {
    fontWeight: 600,
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
});
