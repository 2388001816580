import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, FormControlLabel, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IncidentResolution } from 'services/incidents/types';
import { KLEditor } from 'components/KLEditor';
import { KLRadio } from 'components/KLRadio';
import { useSelector } from 'store/hook';
import { IncidentState } from 'services/incidents/slice';
import { CloseIncidentFormProps } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

export const ResolveIncidentForm: React.FC<CloseIncidentFormProps> = props => {
  const { state, setState, isLoading = false } = props;
  const { t } = useTranslation('Summary');
  const classes = useStyles();
  const { details } = useSelector((state: { incidents: IncidentState }) => state.incidents);

  const handleCommentChange = (text: string | null) => {
    setState({ ...state, comment: text || '' });
  };

  const handleResolutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, resolution: ((event.target as HTMLInputElement).value as IncidentResolution) });
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl} fullWidth>
        <KLEditor
          initialText={details?.clientComment}
          toolbarPosition="top"
          label={t('Please describe, why you are going to close this incident')}
          placeholder={t('Comment text')}
          text={state.comment}
          setText={handleCommentChange}
          minRows={4}
          maxRows={4}
          fontSize={14}
          lineHeight={20}
          readOnly={isLoading}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth disabled={isLoading}>
        <FormLabel>{t('Resolution')}</FormLabel>
        <RadioGroup
          name="resolution"
          value={state.resolution}
          onChange={handleResolutionChange}
        >
          <FormControlLabel control={<KLRadio />} label={t('TruePositive')} value={IncidentResolution.TruePositive} />
          <FormControlLabel control={<KLRadio />} label={t('FalsePositive')} value={IncidentResolution.FalsePositive} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export * from './types';
