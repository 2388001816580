import Select, { ValueType } from 'react-select';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import { ActionMetaExtra, KLMultiSelectValue } from './types';
import styles from './styles';
import { useClearIndicator, useMultiValue } from './components';
import { customStyles, getValue, onChangeChecked } from './helpers';

const useStyles = makeStyles(styles);

export type SelectAsyncProps = {
  value: KLMultiSelectValue[];
  options: KLMultiSelectValue[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  load?: (searchPhrase: string) => void;
  loading?: boolean;
}
export const SelectAsync = (props: SelectAsyncProps) => {
  const { t } = useTranslation('common');
  const { value, options, isMulti = true,
    onChange, placeholder, disabled,
    load = () => undefined,
    loading = false,
  } = props;
  const classes = useStyles();

  const handleInputChangeAsync = useMemo(() => debounce(300, (inputValue: any) => {
    load(inputValue);
  }), [load]);

  const handler = onChangeChecked({
    value: value.map(getValue),
    options: options.map(getValue),
    onChange,
  });

  const MultiValue = useMultiValue({});
  const ClearIndicator = useClearIndicator();

  return (
    <Select
      className={classes.root}
      components={{
        DropdownIndicator: null,
        ClearIndicator,
        MultiValue,
      }}
      onChange={(_, meta) => handler({
        rawMeta: meta as ActionMetaExtra,
        isMulti,
        value: _ as unknown as ValueType<KLMultiSelectValue>,
      })}
      onInputChange={handleInputChangeAsync}
      isMulti={isMulti}
      isClearable
      filterOption={() => true}
      options={options}
      isLoading={loading}
      placeholder={placeholder}
      value={value}
      styles={customStyles}
      noOptionsMessage={() => t('No options')}
      loadingMessage={() => t('Loading...')}
      isDisabled={disabled}
    />
  );
};
