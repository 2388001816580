import { CacheItemPending, CacheItemResolved, CacheData, CacheItem, CacheItemReject } from "./types";

export type CreateCacheDataProps = {
  expiresDuration: number;
  value: string;
}
export const createCacheData = (props: CreateCacheDataProps): CacheData => {
  const { expiresDuration, value } = props;
  const currentDate = Date.now();
  return {
    value,
    expires: currentDate + expiresDuration,
  };
}

export type createResolveCacheItemProps = {
  expiresDuration: number;
  value: string;
  id: string;
}
export const resolveCacheItem = (props: createResolveCacheItemProps): CacheItemResolved => {
  const { id, value, expiresDuration } = props;
  const data = createCacheData({ expiresDuration, value });
  return { id, data, state: 'resolved' };
}

export type PendingCacheItemProps = {
  id: string;
}
export const pendingCacheItem = (props: PendingCacheItemProps): CacheItemPending => {
  const { id, } = props;
  return { id, state: 'pending' };
}

export type RejectCacheItemProps = {
  id: string;
  value: string;
}
export const rejectCacheItem = (props: RejectCacheItemProps): CacheItemReject => {
  const { id, value } = props;
  return { id, state: 'rejected', data: value };
}

export const isResolvedCacheItem = (data: CacheItem): data is CacheItemResolved => {
  return data.state === 'resolved';
}

export const isPendingCacheItem = (data: CacheItem): data is CacheItemPending => {
  return data.state === 'pending';
}

export const isRejectedCacheItem = (data: CacheItem): data is CacheItemReject => {
  return data.state === 'rejected';
}

export const isExpiredItem = (data: CacheItem): boolean => {
  if (!isResolvedCacheItem(data)) {
    return false;
  }
  const currentDate = Date.now();
  return currentDate > data.data.expires;
} 