import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import { IconButton, InputBase, Tooltip, Paper, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { CloseIcon } from 'assets/icons/CloseIcon';
import styles from './styles';

type SearchProps = {
  open: boolean;
  disabled: boolean;
  onClick: () => void;
  onClose: () => void;
  onSearchChange: (searchText: string | undefined) => void;
  maxLength?: number;
  initialSearchText?: string | null;
  locator?: string;
  searchFields?: { [key: string]: string | undefined };
  placeholder?: string;
};

const useStyles = makeStyles(styles);

export const Search: React.FC<SearchProps> = React.memo(props => {
  const {
    open,
    disabled,
    onClick,
    onClose,
    onSearchChange,
    maxLength,
    initialSearchText,
    locator,
    searchFields,
    placeholder,
  } = props;
  const classes = useStyles();
  const searchRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [wasSearch, setWasSearch] = useState(false);
  const [openHint, setOpenHint] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const { t } = useTranslation('common');

  useOnclickOutside(searchRef, () => {
    onClose();
    setWasSearch(false);
    setAnchorEl(null);
  }, { disabled: !open || !!inputRef?.current?.value });

  useEffect(() => {
    setSearchText(initialSearchText || '');
  }, [initialSearchText]);

  useEffect(() => {
    if (!inputRef.current || disabled) return;
    if ((open && !searchText) || wasSearch) {
      setAnchorEl(inputRef.current);
      inputRef.current.focus();
    }
  }, [open, wasSearch, searchText, disabled]);

  const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const { value: text } = event.target;

    const cutSearchText: string = (maxLength && text.length > maxLength)
      ? text.substring(0, maxLength)
      : text;

    onSearchChange(cutSearchText);
    setSearchText(cutSearchText);
    setWasSearch(true);
  }, [onSearchChange, setSearchText, maxLength]);

  const handleSearchTextClear = () => {
    onSearchChange('');
    setSearchText('');
  };

  const handleSearchTextFocus = () => {
    setOpenHint(true);
  };

  const handleSearchTextBlur = () => {
    setOpenHint(false);
  };

  return (
    <div ref={searchRef} style={{ position: 'relative' }}>
      {!open && (
        <Tooltip title={t('Search') || ''} arrow>
          <IconButton
            id={locator ? `${locator}_button_search` : undefined}
            onClick={onClick}
          >
            <SearchIcon fill="#000000" />
          </IconButton>
        </Tooltip>
      )}
      {open && (
        <>
          <InputBase
            inputRef={inputRef}
            classes={{
              root: classes.root,
              input: classes.input,
            }}
            startAdornment={<SearchIcon fill="#58595B" />}
            endAdornment={(
              <IconButton
                size="small"
                onClick={handleSearchTextClear}
                disabled={!searchText}
              >
                <CloseIcon
                  stroke={searchText ? '#000000' : '#CCCCCC'}
                  strokeWidth={1}
                />
              </IconButton>
            )}
            onChange={handleSearchTextChange}
            onFocus={handleSearchTextFocus}
            onBlur={handleSearchTextBlur}
            value={searchText}
            disabled={disabled}
            spellCheck={false}
            inputProps={{ maxLength: 100 }}
            placeholder={placeholder}
          />
          {searchFields && (
            <Popper
              id={locator ? `${locator}_search_hint` : undefined}
              open={openHint}
              anchorEl={anchorEl}
              container={anchorEl?.parentElement}
              placement={'bottom'}
              style={{ zIndex: 100 }}
              modifiers={{
                preventOverflow: {
                  escapeWithReference: true,
                },
              }}
            >
              <Paper className={classes.hint} square>
                <strong>{t('Search by')}:</strong><br />
                <ul className={classes.fields}>
                  {Object.keys(searchFields).map((field, i) => (
                    <li key={i}>{t(searchFields[field] as string)}</li>
                  ))}
                </ul>
              </Paper>
            </Popper>
          )}
        </>
      )}
    </div>
  );
});
