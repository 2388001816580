import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'store/hook';
import { ItemsContext } from '../DonutChart';
import colorMatching from '../../utils/colorMatching';
import nameMatching from '../../utils/nameMatching';

type NameToUrlMatching = {
  low: string;
  normal: string;
  high: string;
  new: string;
  rejected: string;
  approved: string;
  ok: string;
  warning: string;
  critical: string;
  offline: string;
  absent: string;
};

type NameKey = keyof NameToUrlMatching;

const generateNameToUrlMatching = (filterTenantsNames: string[]) => {
  const urlMatching: NameToUrlMatching = {
    low: '/incidents?priorities=1&statuses=Open&statuses=OnHold',
    normal: '/incidents?priorities=2&statuses=Open&statuses=OnHold',
    high: '/incidents?priorities=3&statuses=Open&statuses=OnHold',
    new: '/incidents?responseStatuses=Waiting',
    rejected: '/incidents?responseStatuses=Rejected',
    approved: '/incidents?responseStatuses=Approved',
    ok: '/assets?lastSeen=all&statuses=0',
    warning: '/assets?lastSeen=all&statuses=1',
    critical: '/assets?lastSeen=all&statuses=2',
    offline: '/assets?lastSeen=all&statuses=4',
    absent: '/assets?lastSeen=all&statuses=5',
  };
  if (filterTenantsNames.length) {
    for (const key in urlMatching) {
      urlMatching[key as NameKey] += filterTenantsNames.reduce(
        (p, c) => `${p}&tenantsNames=${c}`,
        '',
      );
    }
  }
  return urlMatching;
};

const ConditionalWrapper = ({ condition, wrapper, children }: any) => (condition ? wrapper(children) : children);

export const CustomLegendStatus = () => {
  const { t } = useTranslation(['StatisticsPage']);

  const {
    statistics: { filterTenantIds },
    tenants: { list },
  } = useSelector((state) => state);

  const filterTenantNames = useMemo(
    () => list
      .filter((tenant) => filterTenantIds.includes(tenant.tenantId) && tenant.name)
      .map((tenant) => (tenant.tenantId === '-' ? '-' : (tenant.name as string))),
    [filterTenantIds, list],
  );

  const nameToUrlMatching = useMemo(
    () => generateNameToUrlMatching(filterTenantNames),
    [filterTenantNames],
  );
  return (
    <ItemsContext.Consumer>
      {({ onMouseEnter, onMouseLeave, data, options, classes }) => (
        <>
          {data?.map((item: any, i: number) => (
            <div
              className={classes.listItem}
              key={item.name}
              data-id={i}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <div
                data-id={i}
                className={classes.marker}
                style={{ backgroundColor: colorMatching[item.name] }}
              />
              <div data-id={i} className={classes.names}>
                <ConditionalWrapper
                  key={item.name}
                  condition={nameToUrlMatching[item.name as NameKey]}
                  wrapper={(children: any) => (
                    <Link to={nameToUrlMatching[item.name as NameKey]}>{children}</Link>
                  )}
                >
                  {t(nameMatching[item.name] || item.name)}
                </ConditionalWrapper>
              </div>
              <div data-id={i} className={classes.values}>
                {item.value}
              </div>
            </div>
          ))}

          {options?.limit ? (
            <div className={classes.listItem}>
              <span>{`${t('License limit exceeded by')} ${options.limit}`}</span>
            </div>
          ) : null}
        </>
      )}
    </ItemsContext.Consumer>
  );
};
