import React, { useMemo, useState, useEffect } from 'react';
import { getMarkdown2html } from 'services/cache/api';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'store/hook';
import { Title } from 'components/Title';
import { Loading } from 'components/Loading';
import { useDispatch } from 'react-redux';
import { CustomMarkdownProps } from './types';
import { useId } from 'utils/useId';
import { isPendingCacheItem, isRejectedCacheItem } from 'services/cache/helpers';
import { DescriptionMarkdown } from './DescriptionMarkdown';

export const CustomMarkdown = ({
  description,
  classes,
  title,
  withCache,
}: CustomMarkdownProps) => {
  const id = useId();
  const dispatch = useDispatch();
  const keyCache = withCache ?? id;
  const cache = useSelector(store => (store.cache.texts.find(e => e.id === keyCache)));

  useEffect(() => {
    dispatch(getMarkdown2html({ id: keyCache, value: description }));
  }, [description, keyCache]);

  if (!cache) {
    return null;
  }
  if (isPendingCacheItem(cache)) {
    return <Loading />;
  }
  if (isRejectedCacheItem(cache)) {
    return <>{cache.data}</>;
  }


  return (
    <div>
      {title && <Title key='title' component="h2" classes={{ root: classes?.descriptionTitle }}>{title}</Title>}
      <DescriptionMarkdown key='description' data={cache.data.value} classes={classes} />
    </div>
  );
};
