import React, { ComponentType } from 'react';
import { MultiValueProps } from 'react-select';
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { KLMultiSelectValue } from '../types';

const useChipStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: theme.spacing(3),
    borderRadius: 2,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    margin: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    padding: '0 6px',
  },
  closeIcon: {
    margin: '0 6px 0 0',
    width: 12,
    height: 12,
  },
}));

type MultiValueParams = {
  selectAllLabel?: string;
  selectAllValue?: string;
}

export const useMultiValue = (params: MultiValueParams): ComponentType<MultiValueProps<KLMultiSelectValue>> => {
  const classes = useChipStyles();
  return props => {
    const { selectAllLabel, selectAllValue } = params;
    const { data, removeProps: { onClick } } = props;
    const labelToDisplay = data.value === selectAllValue ? selectAllLabel : data.label;

    return (
      <Chip
        color="primary"
        classes={{ root: classes.root, label: classes.label }}
        label={labelToDisplay}
        onDelete={onClick}
        onMouseDown={ev => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
        deleteIcon={(<CloseIcon classes={{ root: classes.closeIcon }} size={12} stroke="#FFFFFF" strokeWidth={1} />)}
      />
    );
  };
};
