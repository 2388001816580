import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, CircularProgress } from '@material-ui/core';
import { useSelector } from 'store/hook';
import { statsRequest, checkRequest, setFilterTenantIds } from 'services/statistics/slice';
import { usePermissions } from 'components/Permissions/hooks';
import { Loading } from 'components/Loading';
import { TelemetryStats } from 'pages/StatisticsPage/TelemetryStats';
import classNames from 'classnames';
import { TableFilter, Toolbar } from 'components/Table/components';
import { FilterIcon } from 'assets/icons/FilterIcon';
import { FilterField } from 'components/Filter';
import { KLMultiSelectType } from 'components/KLMultiSelect';
import {
  listRequest as listTenantRequest,
} from 'services/tenants/slice';
import { AssetListFilters } from 'services/assets/types';
import { save, load } from 'utils/storage';
import { UnderConstruction } from './components/UnderConstruction';
import useStyles from './utils/useStyles';
import AssetsByStatusStats from './components/AssetsByStatusStats';
import ActiveIncidentsStats from './components/ActiveIncidentsStats';
import ResponsesStats from './components/ResponsesStats';
import AssetsStats from './components/AssetsStats';

export const StatsFilterTenantIds = 'StatsFilterTenantIds';

export const filterFields: FilterField<AssetListFilters>[] = [
  {
    type: 'selected',
    selectType: KLMultiSelectType.checked,
    title: 'Tenant',
    filter: 'tenantsNames',
    field: 'tenantName',
    request: listTenantRequest,
    placeholder: 'filters:Start entering name',
    transNamespace: 'filters',
  },
];

export const StatisticsPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['StatisticsPage']);
  const [viewLicensePerm] = usePermissions(['view_organization_license']);
  const dispatch = useDispatch();

  const { isLoading, isChecking, isAvailable, filterTenantIds } = useSelector(
    (state) => state.statistics,
  );
  const {
    isLoading: tenantLoading,
    list: tenantItems,
    listIdle: tenantIdle,
  } = useSelector((state: { tenants: any }) => state.tenants);
  const makeTenantSuggestion = ({ name, tenantId }: any) => {
    if (tenantId === '-') {
      return { value: tenantId, label: t('Root tenant') };
    }
    return { value: tenantId, label: name };
  };

  const customFilterFields = useMemo(() => filterFields.map(field => {
    switch (field.field) {
      case 'tenantName': {
        const options = tenantItems?.map(makeTenantSuggestion);
        return {
          ...field,
          options,
          isRequesting: tenantLoading,
          isIdle: tenantIdle,
        };
      }
      default: return field;
    }
  }), [tenantIdle, tenantItems,
    tenantLoading]);
  const [filters, setFilters] = useState<AssetListFilters>({ tenantsNames: [] });
  const [fitlersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    dispatch(checkRequest());
  }, [dispatch]);

  useEffect(
    () => {
      const filters = load('tenantFilters');
      if (filters) {
        setFilters(filters);
        dispatch(setFilterTenantIds(filters.tenantsNames));
      }
    },
    [],
  );

  useEffect(() => {
    if (isAvailable) {
      dispatch(statsRequest({ 
        tenantIds: filterTenantIds ?? [],
      }));
    }
  }, [isAvailable, dispatch, filterTenantIds]);

  if (isChecking || isAvailable === null) {
    return <Loading />;
  }

  if (isAvailable === false) {
    return <UnderConstruction />;
  }

  const onSetFilters = (e: AssetListFilters) => {
    const filters = Object.fromEntries(Object.entries(e).map(([key, value]) => [key, value ?? []]));
    setFilters(filters);
    save('tenantFilters', filters);
    dispatch(setFilterTenantIds(filters.tenantsNames));
  };

  return (
    <div>
      <Toolbar
        extraActions={[]}
        title={t('Summary')}
        actions={[
          {
            icon: () => (
              <Badge
                variant="dot"
                color="primary"
                classes={{}}
                badgeContent={filters.tenantsNames?.length}
              >
                <FilterIcon />
              </Badge>
            ),
            isFreeAction: false,
            position: 'toolbar',
            onClick: () => setFiltersOpen(!fitlersOpen),
            hidden: false,
            tooltip: t('Filter'),
            locator: undefined,
          },
        ]}
      />
      <TableFilter<AssetListFilters>
        filters={filters}
        setFilters={onSetFilters}
        filterFields={customFilterFields}
        transNamespace="AssetListPage"
        setPage={() => undefined}
        open={fitlersOpen}
        closeFilters={() => setFiltersOpen(false)}
      />
      <div className={classes.root}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className={classes.row}>
              <div className={classNames(classes.cell, classes.cellSm)}>
                {viewLicensePerm && <AssetsStats />}
              </div>
              <div className={classNames(classes.cell, classes.cellSm)}>
                <ActiveIncidentsStats />
              </div>
            </div>
            <div className={classes.row}>
              <div className={classNames(classes.cell, classes.cellSm)}>
                <div className={classes.chartContainer}>
                  <div className={classes.chartTile}>
                    <TelemetryStats />
                  </div>
                </div>
              </div>
              <div className={classNames(classes.cell, classes.cellSm)}>
                <div className={classes.chartContainer}>
                  <ResponsesStats />
                  <AssetsByStatusStats />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
