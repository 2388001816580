import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0, 4, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '26px',
  },
  formWrapper: {
    flexGrow: 1,
  },
  formBlock: {
    marginBottom: theme.spacing(1),
  },
  body: {
    flexGrow: 1,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
    color: '#58595B',
    fontSize: theme.typography.pxToRem(14),
  },
  labelBottom: {
    marginTop: theme.spacing(1),
    color: '#58595B',
    fontSize: theme.typography.pxToRem(14),
  },
  action: {
    marginTop: '20px',
  },
  textarea: {
    minHeight: '106px',
    resize: 'none',
    outline: 'none',
    width: '100%',
    border: '1px solid #B3B3B3',
    borderRadius: '3px',
    boxShadow: 'inset 0px 3px 4px rgba(29, 29, 29, 0.05), inset 0px 2px 4px rgba(29, 29, 29, 0.05)',
    padding: '5px 8px',
  },
  disclaimer: {
    marginTop: '8px',
    marginBottom: '20px',
  },
  textareaWrapper: {
    position: 'relative',
  },
  refresh: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  cell: {
    padding: '0 20px 20px 0',
    '&:first-of-type': {
      color: '#58595B',
    },
  },
  buttonsHeader: {
    marginBottom: '12px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsNotification: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'relative',
  },
  notificationHeader: {
    position: 'absolute',
    top: '3px',
  },
  notificationText: {
    marginLeft: '30px',
  },
  footer: {
    padding: theme.spacing(2, 1, 0),
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button + button': {
      marginLeft: theme.spacing(1),
    },
  },
  afterFieldTooltipInfoIcon: {
    verticalAlign: 'sub',
  },
});
