import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KLMultiSelectProps, KLMultiSelectType } from './types';
import { SelectChecked } from './SelectChecked';
import { SelectAsync } from './SelectAsync';
import { SelectCached } from './SelectCached';
import { SelectCreatable } from './SelectCreatable';
import { SelectDefault } from './SelectDefault';

export const
  KLMultiSelect: React.FC<KLMultiSelectProps> = props => {
    const { t } = useTranslation('common');
    const {
      onChange,
      value,
      options = [],
      loading = false,
      load,
      placeholder,
      disabled = false,
      menuPlacement = 'auto',
      isMulti = true,
      type = KLMultiSelectType.default,
      selectAllLabel = t('All'),
      isIdle = false,
    } = props;

    switch (type) {
      case KLMultiSelectType.creatable:
        return (
          <SelectCreatable
            onChange={onChange}
            options={options}
            value={value}
            disabled={disabled}
            isMulti={isMulti}
            placeholder={placeholder ?? t('Search')}
          />
        );
      case KLMultiSelectType.async:
        return (
          <SelectAsync
            onChange={onChange}
            options={options}
            value={value}
            disabled={disabled}
            isMulti={isMulti}
            load={load}
            loading={loading}
            placeholder={placeholder ?? t('Search')}
          />
        );

      case KLMultiSelectType.cached:
        return (
          <SelectCached
            onChange={onChange}
            options={options}
            value={value}
            disabled={disabled}
            isIdle={isIdle}
            isMulti={isMulti}
            load={load}
            loading={loading}
            placeholder={placeholder}
          />
        );
      case KLMultiSelectType.checked:
        return (
          <SelectChecked
            onChange={onChange}
            options={options}
            value={value}
            disabled={disabled}
            menuPlacement={menuPlacement}
            placeholder={placeholder}
            selectAllLabel={selectAllLabel}
            load={load}
            loading={loading}
            isIdle={isIdle}
          />
        );
      case KLMultiSelectType.default:
      default:
        return (
          <SelectDefault
            onChange={onChange}
            options={options}
            value={value}
            disabled={disabled}
            isMulti={isMulti}
            menuPlacement={menuPlacement}
            placeholder={placeholder ?? t('Search')}
          />
        );
    }
  };

export * from './types';
