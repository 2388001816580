import React from 'react';

import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'store/hook';
import { usePermissions } from 'components/Permissions/hooks';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/Table';
import { DateTime } from 'components/DateTime';
import { KLButton } from 'components/KLButton';

import { AddIcon } from 'assets/icons/AddIcon';
import { DeleteIcon } from 'assets/icons/DeleteIcon';

import {
  listRequest,
  setDrawerData,
  terminateSessionRequest,
} from 'services/APIkeys/slice';
import { RobotSessionStatus, RobotSessionItem } from 'services/APIkeys/types';

import { IS_HIDE_TENANTS } from 'global/environments';

import styles from './styles';

const useStyles = makeStyles(styles);
const statusNames = Object.values(RobotSessionStatus);

export const APIkeys: React.FC = () => {
  const { t } = useTranslation(['SettingsPage', 'common']);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const [
    viewOrganizationRobots,
    editOrganizationRobots,
  ] = usePermissions([
    'view_organization_robots',
    'edit_organization_robots',
  ]);

  const { list, isLoading } = useSelector(state => state.APIkeys);

  const onAddKey = (): void => {
    dispatch(setDrawerData(null));
  };

  const onTerminateSession = (event: any, data: any) => {
    if (!data) return;
    const { stateToken, sessionId } = data;
    dispatch(terminateSessionRequest({ stateToken, sessionId }));
  };

  const onRowClick = (event: any, data: RobotSessionItem | undefined) => {
    if (data) {
      const { creationTime, expirationTime, sessionId, sessionName, stateToken, status, tenants, roleId } = data;
      dispatch(setDrawerData({
        creationTime,
        expirationTime,
        sessionId,
        sessionName,
        stateToken,
        status,
        tenants,
        roleId,
      }));
    }
  };

  if (!viewOrganizationRobots) {
    return <div>No permission to view api keys</div>;
  }

  return (
    <div>
      <Table<RobotSessionItem>
        onRowClick={onRowClick}
        action={listRequest}
        actions={editOrganizationRobots ? [
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: t('API.Delete key'),
            position: 'row',
            onClick: onTerminateSession,
          },
        ] : []}
        extraActions={editOrganizationRobots ? [
          <KLButton
            color="primary"
            variant="contained"
            startIcon={<AddIcon fill="#FFFFFF" />}
            onClick={onAddKey}
          >
            {t('API.Add')}
          </KLButton>,
        ] : []}
        data={list}
        count={list.length}
        columns={[
          {
            searchable: false,
            sorting: false,
            width: `${theme.spacing(4)}px`,
          },

          {
            title: t('API.Creating Date'),
            field: 'creationTime',
            searchable: false,
            sorting: false,
            render: ({ creationTime }) => (creationTime
              ? <DateTime timestamp={creationTime} formatString="yyyy-MM-dd HH:mm" /> : null
            ),
            cellStyle: {
              whiteSpace: 'normal',
            },
          },

          {
            title: t('API.Status'),
            field: 'status',
            searchable: false,
            sorting: false,
            render: ({ status }) => t(`API.${statusNames[status]}`),
            cellStyle: {
              whiteSpace: 'normal',
            },
          },

          {
            title: t('API.Refresh Until'),
            field: 'expirationTime',
            searchable: false,
            sorting: false,
            render: ({ expirationTime }) => (expirationTime
              ? <DateTime timestamp={expirationTime} formatString="yyyy-MM-dd HH:mm" /> : null
            ),
            cellStyle: {
              whiteSpace: 'normal',
            },
          },

          {
            title: t('API.Connection Name'),
            field: 'sessionName',
            searchable: false,
            sorting: false,
            render: ({ sessionName }) => sessionName,
            cellStyle: {
              whiteSpace: 'normal',
            },
          },

          {
            title: t('Tenant'),
            hidden: IS_HIDE_TENANTS,
            render: ({ tenants }) => (`${(tenants)
              .slice(0, 2)
              .map(({ tenantName }) => (tenantName === '-' ? t('Root tenant') : tenantName))
              .join(', ')
              || t('common:No')
            }${tenants.length > 2 ? `, ${tenants.length - 2} ${t('common:more')}...` : ''}`),
            cellStyle: (data, { tenants }) => ({
              color: tenants.length ? 'inherit' : '#979797',
              whiteSpace: 'normal',
            }),
          },
        ]}
        title={t('API.All tokens')}
        isLoading={isLoading}
        options={{
          showTextRowsSelected: false,
          paging: false,
          actionsColumnIndex: -1,
          tableLayout: 'auto',
        }}
      />

    </div>
  );
};
