import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import theme from 'themes/default';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { getLocale } from 'utils/i18n';
import { DataLine } from '../../DataLine';
import styles from './styles';

export type Tab = 'active' | 'all';

export type LicenseTableProps = {
  licensesCount?: number;
  region?: string;
  solution?: string;
  assetsCount?: number;
  assetsLimit?: number;
  tab: Tab;
  onTabChange: (tab: Tab) => void;
  onDownloadReport: () => void;
};

const useStyles = makeStyles(styles);

export function LicensesTableHeader(
  { licensesCount, region, solution, assetsLimit, assetsCount, tab, onTabChange, onDownloadReport }: LicenseTableProps,
) {
  const { t } = useTranslation();
  const classes = useStyles();
  const telemetryRegion = (
    region === "rus" ? "ru" :
    region === "russia" ? "ru" :
    region === "rest" ? "ru" :
    region === "eu" ? "neu" :
    region === "europe" ? "neu" :
    region === "canada" ? "neu" :
    region);

  const onAllLicensesClick = useCallback(() => { onTabChange('all'); }, [onTabChange]);
  const onActiveLicesesClick = useCallback(() => { onTabChange('active'); }, [onTabChange]);

  return (
    <div className={classes.root}>
      {
        region !== undefined
          ? (
            <DataLine
              title={t('organizations:Region')}
              value={(
                <>
                  {t(`ActivationPage:${telemetryRegion}`)}
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={(
                      <>
                        {t('LicenseStatePage:regionTooltip')}&nbsp;
                        <a
                          href={t('LicenseStatePage:regionTooltipLink', { locale: getLocale() })}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('LicenseStatePage:regionTooltipLinkText', { locale: getLocale() })}
                        </a>.
                      </>
                    )}
                    arrow
                    interactive
                    placement="right"
                    leaveDelay={1000}
                  >
                    <span>
                      <InfoIcon className={classes.infoIcon} fill={theme.palette.info.main} />
                    </span>
                  </Tooltip>
                </>
                )}
            />
          )
          : null
      }
      {
        solution !== undefined
          ? (<DataLine title={t('LicenseStatePage:Solution')} value={t(`LicenseStatePage:${solution}`)} />)
          : null
      }
      {
        assetsCount !== undefined && assetsLimit !== undefined
          ? (
            <DataLine title={t('LicenseStatePage:Assets')} value={(
              <>
                <span>
                  <span className={assetsCount > assetsLimit ? classes.error : undefined}>
                    {assetsCount}
                  </span> / {assetsLimit}
                </span>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t('LicenseStatePage:assetsTooltip') as string}
                  arrow
                  interactive
                  placement="right"
                  leaveDelay={1000}
                >
                  <span>
                    <InfoIcon className={classes.infoIcon} fill={theme.palette.info.main} />
                  </span>
                </Tooltip>
              </>
                )}
            />
          )
          : null
      }
      <div className={classes.tabs}>
        <a
          className={classNames(classes.tab, { [classes.activeTab]: tab === 'active' })}
          onClick={onActiveLicesesClick}
        >
          {t('LicensesTable:TabActive')}
        </a>
        <a
          className={classNames(classes.tab, { [classes.activeTab]: tab === 'all' })}
          onClick={onAllLicensesClick}
        >
          {t('LicensesTable:TabAll')}
        </a>
        {
          licensesCount && licensesCount > 1 && (
            <>
              <span className={classes.gap} />
              <span className={classes.reportLinkWrap}>
                <a
                  className={classes.reportLink}
                  onClick={onDownloadReport}
                >
                  {t('LicensesTable:DownloadReportLinkName')}
                </a>
                &nbsp;{t('LicensesTable:DownloadReportLinkSuffix')}
              </span>
            </>
          )
        }
      </div>
    </div>
  );
}
