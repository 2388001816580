import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState } from 'store/slice';
import { TenantItem, TenantSuggestion } from './types';

const name = 'tenants';

export const listRequest = createAction<any>(`${name}/list`);
export const deleteRequest = createAction<string>(`${name}/delete`);
export const validateRequest = createAction<TenantItem | {} | undefined>(`${name}/validate`);
export const createRequest = createAction<any>(`${name}/create`);
export const configRequest = createAction<any>(`${name}/archive`);
export const suggestionRequest = createAction<string>(`${name}/suggestion`);
export const hasAccessToRootRequest = createAction(`${name}/hasAccessToRoot`);

export type TenantState = EntityState<TenantItem, TenantItem | undefined> & {
  suggestion?: TenantSuggestion[];
  suggestionLoading?: boolean;
  hasAccessToRoot?: boolean;
  listIdle?: boolean;
};
const initialState: TenantState = {
  listIdle: true,
  list: [],
  page: 1,
  pageSize: 10,
  filters: {},
  columns: null,
  search: '',
  isLoading: false,
  count: 0,
  details: null,
  error: null,
  unreadCount: 0,
  suggestion: [],
  suggestionLoading: false,
};

const tenantsListSlice = createEntitySlice({
  name,
  initialState,
  reducers: {
    startSuggestion: (state: TenantState) => {
      state.suggestionLoading = true;
    },
    setSuggestion: (state: TenantState, action: PayloadAction<TenantSuggestion[]>) => {
      state.suggestionLoading = false;
      state.suggestion = action.payload;
    },
    setHasAccessToRoot: (state: TenantState, action: PayloadAction<boolean>) => {
      state.hasAccessToRoot = action.payload;
    },
    setListIdle: (state: TenantState, action: PayloadAction<boolean>) => {
      state.listIdle = action.payload;
    },
  },
});

export const {
  listStart,
  listSuccess,
  setListIdle,
  detailsStart,
  detailsSuccess,
  error,
  startSuggestion,
  setSuggestion,
  setHasAccessToRoot,
} = tenantsListSlice.actions;

export default tenantsListSlice.reducer;
