import React, { useEffect, useMemo } from 'react';
import { Table } from 'components/Table';
import { useTranslation } from 'react-i18next';
import filesize from 'filesize';
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import {
  IncidentHistoryEntityType,
  IncidentHistoryItem,
  IncidentHistoryItemValue,
} from 'services/incidentHistory/types';
import { getLang } from 'utils/i18n';
import { DateTime } from 'components/DateTime';
import { setColumns, setFilters, setSearch } from 'services/incidentHistory/slice';
import { ResponseStatus } from 'services/incidentResponses/types';
import { CustomMarkdown } from '../CustomMarkdown';
import * as filtersConfig from '../../../../configs/filters.json';
import { FilterTableTitle } from '../FilterTableTitle';
import { HistoryColumnDescription, HistoryInnerProps } from './types';

const tableOptions = {
  paging: false,
  columnsButton: true,
  search: true,
  filtering: true,
  headerStyle: {
    borderTop: 'none',
  },
};

export const HistoryInner: React.FC<HistoryInnerProps> = props => {
  const { history = [], columnsSettings, isLoading = false } = props;
  const { t } = useTranslation(['IncidentHistory', 'common', 'Summary', 'IncidentResponses', 'Communication']);
  const theme = useTheme();

  const memoFilterFields: any = useMemo(() => ([
    {
      type: 'checked',
      title: t('Incident'),
      filter: 'incident',
      field: null,
      items: filtersConfig.incidentHistory.incident,
      transNamespace: 'incidentHistoryFilters',
    },
    {
      type: 'checked',
      title: t('Response'),
      filter: 'response',
      field: null,
      items: filtersConfig.incidentHistory.response,
    },
    {
      type: 'checked',
      title: t('Communication'),
      filter: 'communication',
      field: null,
      items: filtersConfig.incidentHistory.communication,
    },
  ]), [t]);
  const renderHistoryState = (
    item: IncidentHistoryItemValue,
    entityType: IncidentHistoryEntityType,
    changedAt: number,
    diffDir: string,
  ): React.ReactNode => (
    Object.keys(item).map(key => {
      let trNamespace: string;
      switch (entityType) {
        case IncidentHistoryEntityType.Incident:
          trNamespace = 'Summary';
          break;
        case IncidentHistoryEntityType.Response:
        case IncidentHistoryEntityType.EDRResponse:
          trNamespace = 'IncidentResponses';
          break;
        case IncidentHistoryEntityType.Comment:
        case IncidentHistoryEntityType.Attachment:
          trNamespace = 'Communication';
          break;
        default:
          trNamespace = 'common';
      }

      let { value } = item[key];
      if (entityType === IncidentHistoryEntityType.Attachment && key === 'fileName') {
        value = decodeURI(value);
      } else if (entityType === IncidentHistoryEntityType.Attachment && key === 'fileSize') {
        value = filesize(value, {
          locale: getLang(),
          symbols: { B: 'Б' },
        });
      } else if (entityType === IncidentHistoryEntityType.Incident && (
        key === 'priority' || key === 'status' || key === 'resolution'
      )) {
        value = t(`${trNamespace}:${value}`);
      } else if (entityType === IncidentHistoryEntityType.Response && key === 'comment') {
        value = value === 'Confirmed by autoresponder'
          ? t('Confirmed by autoresponder')
          : value;
      } else if (entityType === IncidentHistoryEntityType.Response && key === 'status') {
        switch (value) {
          case ResponseStatus.waiting:
            value = t(`${trNamespace}:New`);
            break;
          case ResponseStatus.rejected:
            value = t(`${trNamespace}:Declined`);
            break;
          case ResponseStatus.approved:
            value = t(`${trNamespace}:Confirmed`);
            break;
          default:
            break;
        }
      } else if (entityType === IncidentHistoryEntityType.Response && key === 'type') {
        value = t(`${trNamespace}:${value}`);
      } else if (entityType === IncidentHistoryEntityType.EDRResponse && key === 'details') {
        const details = { ...value };
        value = (
          <>
            <Box>
              <Box component="span" fontWeight="fontWeightBold">{`${t(`${trNamespace}:edr_type`)}: `}</Box>
              <Box component="span">{t(`${trNamespace}:edrTypes.${details.edr_type}`)}</Box>
            </Box>
            {details.ksc_host_id && (
              <Box>
                <Box component="span" fontWeight="fontWeightBold">{`${t(`${trNamespace}:ksc_host_id`)}: `}</Box>
                <Box component="span">{details.ksc_host_id}</Box>
              </Box>
            )}
            {details.ksc_hosts_ids && (
              <Box>
                <Box component="span" fontWeight="fontWeightBold">{`${t(`${trNamespace}:ksc_hosts_ids`)}: `}</Box>
                <Box component="span">{details.ksc_hosts_ids.join(', ')}</Box>
              </Box>
            )}
          </>
        );
      } else if (value instanceof Array) {
        value = value.join(', ');
      } else {
        value = String(value);
      }

      return (
        <Box key={key}>
          {(!(entityType === IncidentHistoryEntityType.Comment && key === 'text')
            && !(entityType === IncidentHistoryEntityType.Attachment && key === 'caption')
            && !(entityType === IncidentHistoryEntityType.EDRResponse && key === 'details')) && (
              <Box component="span" fontWeight="fontWeightBold">{`${t(`${trNamespace}:${item[key].trKey}`)}: `}</Box>
            )}
          {(item[key].isMarkdown && value.length) ? (
            <CustomMarkdown key={`incidentHistory-${key}-${changedAt}-${diffDir}`}
              withCache={`incidentHistory-${key}-${changedAt}-${diffDir}`} description={value} />
          ) : (
            <Box style={{ whiteSpace: 'pre-wrap' }} component="span">{value.length || value ? value : '-'}</Box>
          )}
        </Box>
      );
    })
  );

  const memoColumns: HistoryColumnDescription[] = useMemo(() => ([
    {
      sorting: false,
      width: `${theme.spacing(4)}px`,
    },
    {
      title: t('Time'),
      field: 'changedAt',
      type: 'datetime',
      defaultSort: 'desc',
      render: rowData => <DateTime timestamp={rowData.changedAt} withTimeSeconds />,
      customSort: (a, b) => a.changedAt - b.changedAt,
    },
    {
      title: t('Member'),
      field: 'changedBy',
      sorting: false,
      render: ({ changedBy }) => {
        if (changedBy === 'Security Team' || changedBy === 'portal' || changedBy === 'autoresponder') {
          return t(changedBy);
        }
        return changedBy;
      },
    },
    {
      title: t('Action'),
      sorting: false,
      cellStyle: {
        whiteSpace: 'normal',
      },
      render: rowData => t(`historyActions.${rowData.operation} ${rowData.entityType}`),
    },
    {
      title: t('Before'),
      field: 'before',
      sorting: false,
      cellStyle: (data, rowData) => ({
        color: rowData.before ? 'inherit' : '#979797',
        width: '40%',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      }),
      render: ({ before, entityType, changedAt }) => (
        before ? renderHistoryState(before, entityType, changedAt, 'BEFORE') : t('common: No')
      ),
    },
    {
      title: t('After'),
      field: 'newValue',
      sorting: false,
      cellStyle: (data, rowData) => ({
        color: rowData.after ? 'inherit' : '#979797',
        width: '40%',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      }),
      render: ({ after, entityType, changedAt }) => (
        after ? renderHistoryState(after, entityType, changedAt, 'AFTER') : t('common: No')
      ),
    },
  ]), [t]);

  const memoTitle = useMemo(() => <FilterTableTitle />, [t, history]);

  return (
    <Grid item xs={12}>
      <Table<IncidentHistoryItem>
        columns={memoColumns}
        data={history}
        count={history.length}
        isLoading={isLoading}
        columnsSettings={columnsSettings}
        title={memoTitle}
        setFilters={setFilters}
        setColumns={setColumns}
        setSearch={setSearch}
        filterFields={memoFilterFields}
        titleClassName="emptyClass"
        options={tableOptions}
      />
    </Grid>
  );
};
