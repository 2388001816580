import { ActionTypes } from 'react-select';

export type KLMultiSelectChangeHandler = (value: string[]) => void;

export enum KLMultiSelectType {
  default,
  creatable,
  async,
  cached,
  checked
}

export type KLMultiSelectProps = {
  value: (KLMultiSelectValue)[];
  onChange: KLMultiSelectChangeHandler;
  type?: KLMultiSelectType;
  options?: (KLMultiSelectValue)[];
  loading?: boolean;
  load?: (searchPhrase: string) => void;
  placeholder?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  isMulti?: boolean;
  selectAllLabel?: string;
  isIdle?: boolean;
};

export type KLMultiSelectValue = {
  label: string;
  value: string;
};

export type ActionMetaExtra =
  { name?: string; action: 'deselect-option'; option: KLMultiSelectValue }
  |
  { name?: string; action: 'select-option'; option: KLMultiSelectValue }
  |
  { name?: string; action: 'remove-value'; removedValue: KLMultiSelectValue }
  |
  { name?: string; action: 'clear' };

export type ActionToInfoResult = {
  name?: string;
  action: ActionTypes;
  option: KLMultiSelectValue;
}

export type ValuesOnSelectProps = {
  values: string[];
  meta: ActionToInfoResult;
}
