import { ValueType } from 'react-select';
import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ActionMetaExtra, KLMultiSelectValue } from './types';
import styles from './styles';
import { useClearIndicator, useMultiValue } from './components';
import { customStyles, getValue, onChangeChecked } from './helpers';

const useStyles = makeStyles(styles);

export type SelectCreatableProps = {
  value: KLMultiSelectValue[];
  options: KLMultiSelectValue[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
}

export const SelectCreatable = (props: SelectCreatableProps) => {
  const { t } = useTranslation('common');
  const { value, options, isMulti = true,
    onChange, placeholder, disabled,
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const handler = onChangeChecked({
    value: value.map(getValue),
    options: options.map(getValue),
    onChange,
  });

  const MultiValue = useMultiValue({});
  const ClearIndicator = useClearIndicator();

  return (
    <CreatableSelect
      className={classes.root}
      components={{
        DropdownIndicator: null,
        ClearIndicator,
        MultiValue,
      }}
      inputValue={inputValue}
      isClearable
      isMulti={isMulti}
      onChange={(_, meta) => handler({
        rawMeta: meta as ActionMetaExtra,
        isMulti,
        value: _ as unknown as ValueType<KLMultiSelectValue>,
      })}
      onInputChange={setInputValue}
      placeholder={placeholder}
      value={value}
      styles={customStyles}
      noOptionsMessage={() => null}
      menuPosition="fixed"
      formatCreateLabel={(inputValue) => <span>{`${t('Create')} "${inputValue}"`}</span>}
      isDisabled={disabled}
    />
  );
};
