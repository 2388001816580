import { ValueType } from 'react-select';
import { ActionMetaExtra, ActionToInfoResult, KLMultiSelectValue, ValuesOnSelectProps } from '../types';

export const emptySelectValue = (): KLMultiSelectValue => ({ value: '', label: '' });

export type ValueToRawMetaProps = {
  rawMeta: ActionMetaExtra;
  value: ValueType<KLMultiSelectValue>;
}
export const valueToRawMeta = (props: ValueToRawMetaProps): ActionMetaExtra => {
  const { rawMeta, value } = props;
  return {
    ...rawMeta,
    option: value as KLMultiSelectValue,
    removedValue: value as KLMultiSelectValue,
  } as unknown as ActionMetaExtra;
};

export const rawMetaToMeta = (rawMeta: ActionMetaExtra): ActionToInfoResult => {
  switch (rawMeta.action) {
    case 'deselect-option':
    case 'select-option': {
      return rawMeta;
    }
    case 'remove-value': {
      return { name: rawMeta.name, action: 'remove-value', option: rawMeta.removedValue };
    }
    default: {
      return { ...rawMeta, option: emptySelectValue() };
    }
  }
};

export type ToCorrectMetaStructureProps = {
  rawMeta: ActionMetaExtra;
  isMulti?: boolean;
  value?: ValueType<KLMultiSelectValue>;
}
export const toCorrectMetaStructure = (params: ToCorrectMetaStructureProps): ActionToInfoResult => {
  const { rawMeta, isMulti = true, value } = params;
  if (isMulti) {
    return rawMetaToMeta(rawMeta);
  }
  const newMeta = valueToRawMeta({ rawMeta, value });
  return rawMetaToMeta(newMeta);
};

export const toCorrectMetaAction = (props: ValuesOnSelectProps): ActionToInfoResult => {
  const {
    values,
    meta,
  } = props;
  if (meta.action !== 'select-option') {
    return meta;
  }
  if (!values.includes(meta.option.value)) {
    return meta;
  }
  return { ...meta, action: 'deselect-option' };
};

export type FormatMeta = {
  metaInfo: ToCorrectMetaStructureProps;
  values: string[];
}
export const formatMeta = (props: FormatMeta) => {
  const { values, metaInfo } = props;
  const meta = toCorrectMetaStructure(metaInfo);
  return toCorrectMetaAction({ meta, values });
};
