import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import { useSelector } from 'store/hook';
import { useConfirmation } from 'components/Confirmation';
import { UserListItem, Tenant } from 'services/user/types';
import {
  deleteInviteRequest,
  deleteUserRequest,
  userListRequest,
  openSideDialog,
  closeSideDialog,
} from 'services/user/slice';
import { Table } from 'components/Table';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { KLButton } from 'components/KLButton';
import { AddIcon } from 'assets/icons/AddIcon';
import { PriorityHigh } from '@material-ui/icons';
import { SETTINGS_PAGE } from 'global/routes';
import { IS_HIDE_TENANTS } from 'global/environments';
import { QuestionMark } from 'assets/icons/QuestionMark';
import { usePermissions } from 'components/Permissions/hooks';
import { SideDialog } from 'components/SideDialog';
import { INVITE_USER_HELP } from 'utils/help';
import { UserRoles } from '../..';
import { DeleteConfirmation } from '../DeleteConfirmation';
import { InviteForm } from '../InviteForm';
import styles from './styles';

const useStyles = makeStyles(styles);

type UserListTabProps = {
  roles: UserRoles;
};

export const UserListTab: React.FC<UserListTabProps> = (props) => {
  const { roles } = props;
  const { list: items, isLoading, sideDialogData } = useSelector(state => state.users);
  const { t } = useTranslation(['SettingsPage', 'common']);
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { openConfirmation, closeConfirmation } = useConfirmation();

  const [editOrganizationUsersPerm] = usePermissions([
    'edit_organization_users',
  ]);

  const onRowClickHandler = (event?: React.MouseEvent, rowData?: UserListItem): void => {
    if (rowData) {
      const { activatedUserInfo, description, inviteInfo, itemType, roleId } = rowData;
      dispatch(openSideDialog({ activatedUserInfo, description, inviteInfo, itemType, roleId }));
    }
  };

  const onAddUser = (): void => {
    dispatch(openSideDialog({}));
  };

  const onDialogCloseHandler = () => {
    dispatch(closeSideDialog());
  };

  const onDeleteUser = (event: any, data: any) => {
    const userData = {
      email: (data.activatedUserInfo && data.activatedUserInfo.email)
        || (data.inviteInfo && data.inviteInfo.inviteEmail),
      role: roles[data.roleId],
    };
    openConfirmation({
      title: t('Confirm user deletion'),
      content: <DeleteConfirmation {...userData} />,
      confirmText: t('Delete'),
      dismissText: t('Cancel'),
      onConfirm: () => {
        const isActiveUser = data && !!data.activatedUserInfo;
        if (isActiveUser) {
          dispatch(deleteUserRequest({
            userId: data.activatedUserInfo.userId,
            userStateToken: data.activatedUserInfo.userStateToken,
          }));
        } else {
          dispatch(deleteInviteRequest({
            inviteId: data.inviteInfo.inviteId,
            inviteStateId: data.inviteInfo.inviteStateId,
          }));
        }
        closeConfirmation();
      },
    });
  };

  return (
    <>
      <Table<UserListItem>
        onRowClick={onRowClickHandler}
        action={userListRequest}
        actions={editOrganizationUsersPerm ? [
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: t('Delete user'),
            position: 'row',
            onClick: onDeleteUser,
          },
        ] : []}
        extraActions={editOrganizationUsersPerm ? [
          <KLButton
            color="primary"
            variant="contained"
            startIcon={<AddIcon fill="#FFFFFF" />}
            onClick={onAddUser}
            id="user-list_button_add"
          >
            {t('Add')}
          </KLButton>,
        ] : []}
        data={items}
        count={items.length}
        route={SETTINGS_PAGE}
        columns={[
          {
            searchable: false,
            sorting: false,
            width: `${theme.spacing(4)}px`,
          },
          {
            title: t('Email'),
            sorting: false,
            render: ({ activatedUserInfo, inviteInfo }) => (
              activatedUserInfo ? activatedUserInfo.email : inviteInfo.inviteEmail
            ),
            width: '30%',
            cellStyle: {
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
            },
          },
          {
            title: t('Role'),
            field: 'roleId',
            searchable: false,
            sorting: false,
            render: ({ roleId }) => roles[roleId],
            width: '20%',
            cellStyle: {
              whiteSpace: 'normal',
            },
          },
          {
            title: t('Tenant'),
            hidden: IS_HIDE_TENANTS,
            render: ({ activatedUserInfo, inviteInfo, roleId }) => {
              let tenants = (activatedUserInfo?.tenants || inviteInfo?.tenants) as Tenant[];
              if (roleId === 0) {
                tenants = [
                  { tenantId: '-', tenantName: t('Root tenant') },
                  { tenantId: '*', tenantName: t('All tenants') },
                ];
              }
              return (`${(tenants)
                .slice(0, 2)
                .map(({ tenantName }) => (tenantName === '-' ? t('Root tenant') : tenantName))
                .join(', ')
                || t('common:No')
              }${tenants.length > 2 ? `, ${tenants.length - 2} ${t('common:more')}...` : ''}`);
            },
            cellStyle: (data, { activatedUserInfo, inviteInfo, roleId }) => ({
              color: activatedUserInfo?.tenants?.length || inviteInfo?.tenants?.length || roleId === 0
                ? 'inherit'
                : '#979797',
              whiteSpace: 'normal',
            }),
          },
          {
            title: t('Status'),
            field: 'status',
            searchable: false,
            sorting: true,
            render: ({ itemType }) => {
              switch (itemType) {
                case 0: {
                  return (
                    <span>
                      <QuestionMark style={{ marginRight: theme.spacing(0.5) }} />
                      {t('Pending activation')}
                    </span>
                  );
                }
                case 3: {
                  return (
                    <span>
                      <PriorityHigh color="error" style={{ fontSize: 12, marginRight: theme.spacing(0.5) }} />
                      {t('Expired')}
                    </span>
                  );
                }
                case 4: {
                  return (
                    <span>
                      <CheckIcon
                        style={{ fontSize: 10, verticalAlign: 'baseline', marginRight: theme.spacing(0.5) }}
                        color={'action'}
                      />
                      {t('Active')}
                    </span>
                  );
                }
                default: {
                  return (
                    <span>
                      <PriorityHigh color="error" style={{ fontSize: 12, marginRight: theme.spacing(0.5) }} />
                      {t('Cancelled')}
                    </span>
                  );
                }
              }
            },
            width: '15%',
            cellStyle: {
              whiteSpace: 'normal',
            },
          },
          {
            title: t('Description'),
            searchable: false,
            field: 'description',
            sorting: false,
            render: ({ description }) => (
              description || (
                <span style={{ color: theme.palette.text.secondary }}>
                  {t('No description')}
                </span>
              )
            ),
            cellStyle: {
              whiteSpace: 'normal',
            },
          },
        ]}
        title={t('All users')}
        isLoading={isLoading}
        options={{
          showTextRowsSelected: false,
          paging: false,
          actionsColumnIndex: -1,
          tableLayout: 'auto',
        }}
        locator="user-list"
        uniqueId="activatedUserInfo.email"
      />
      <SideDialog<UserListItem | {}>
        helpId={INVITE_USER_HELP}
        state={{}}
        open={!!sideDialogData}
        title={sideDialogData && Object.entries(sideDialogData).length ? t('Edit user card') : t('Invite new user')}
        onClose={onDialogCloseHandler}
        fullContentSpace
        render={() => (
          <InviteForm sideDialogData={sideDialogData} roles={roles} />
        )}
      />
    </>
  );
};
