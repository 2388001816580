import Select, { ValueType } from 'react-select';
import React, { Dispatch } from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import { ActionMetaExtra, KLMultiSelectValue } from './types';
import { useClearIndicator, useMultiValue, useDropdownIndicator } from './components';
import { customStyles, getValue, onChangeChecked } from './helpers';

const useStyles = makeStyles(styles);

export type SelectDefaultProps = {
  value: KLMultiSelectValue[];
  options: KLMultiSelectValue[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  disabled?: boolean;
  isMulti?: boolean;
}

export const SelectDefault = (props: SelectDefaultProps) => {
  const { value, options,
    onChange, placeholder, menuPlacement, disabled, isMulti = true } = props;
  const classes = useStyles();

  const handler = onChangeChecked({
    value: value.map(getValue),
    options: options.map(getValue),
    onChange,
  });

  const MultiValue = useMultiValue({});
  const ClearIndicator = useClearIndicator();
  const DropdownIndicator = useDropdownIndicator();

  return (
    <Select
      className={classes.root}
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValue,
      }}
      options={options}
      value={value}
      onChange={(_, meta) => handler({
        rawMeta: meta as ActionMetaExtra,
        isMulti,
        value: _ as unknown as ValueType<KLMultiSelectValue>,
      })}
      isClearable
      isMulti={isMulti}
      placeholder={placeholder}
      styles={customStyles}
      noOptionsMessage={() => null}
      menuPlacement={menuPlacement}
      isDisabled={disabled}
      openMenuOnClick
      openMenuOnFocus
    />
  );
};
