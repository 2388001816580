import { KLDateValue } from 'components/KLDatePicker';

export function getDefaultEndDate(maxEndDate?: number, licenseEndDate?: number): number | null {
  if (maxEndDate && licenseEndDate) {
    return licenseEndDate > maxEndDate ? maxEndDate : licenseEndDate;
  }
  if (licenseEndDate) {
    return licenseEndDate;
  }
  if (maxEndDate) {
    return maxEndDate;
  }
  return null;
}

export const isRootTenant = (tenantId?: string) => tenantId === '-';

export function canSubmitForm({
  tenantName,
  start,
  end,
  tenantId,
}: {
  tenantName?: string;
  start?: KLDateValue;
  end?: KLDateValue;
  tenantId?: string;
}): boolean {
  const hasRequiredFields = Boolean(tenantName && start && end);
  return hasRequiredFields && !isRootTenant(tenantId);
}

