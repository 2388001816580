import { postRequest } from 'utils/http';
import { RobotSessionResponse, TenantRequest } from './types';

const url = 'keys';

export function getList() {
  return postRequest(`${url}/list`);
}

export function startSession(sessionName: string, tenants: TenantRequest[], roleId: number, accessToAllTenants: boolean) {
  return postRequest<RobotSessionResponse>(`${url}/start`, { accessToAllTenants, sessionName, tenants, roleId });
}

export function restartSession(stateToken: string, sessionId: string) {
  return postRequest<RobotSessionResponse>(`${url}/restart`, { stateToken, sessionId });
}

export function terminateSession(session: any) {
  return postRequest(`${url}/terminate`, { session });
}

export function updateSession(
  stateToken: string,
  sessionId: string,
  sessionName: string,
  tenants: TenantRequest[],
  roleId: number,
  accessToAllTenants: boolean,
) {
  return postRequest<RobotSessionResponse>(`${url}/update`, {
    accessToAllTenants,
    stateToken,
    sessionId,
    sessionName,
    tenants,
    roleId,
  });
}
