// export type KLDateType = 'all' | 'day' | 'week' | 'month' | 'year' | 'custom';
export enum KLDateType {
  all = 'all',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  custom = 'custom',
}

export type KLDateValue = number | null;

export interface KLDate {
  type: KLDateType;
  start: KLDateValue;
  end: KLDateValue;
}

export type KLDateChangeHandler = (date: KLDate | undefined) => void;

export type KLDatePickerProps = {
  type: KLDateType;
  start: KLDateValue;
  end: KLDateValue;
  onChange: KLDateChangeHandler;
  useTextInput?: boolean;
  minDate?: number;
  maxDate?: number;
  disabled?: boolean;
  fixedStartDate?: boolean;
  error?: boolean;
};
