import React from 'react';
import { useTranslation } from 'react-i18next';
import { SetQuery } from 'use-query-params';
import { Filter, FilterField, FilterType } from 'components/Filter';
import { SideDialog } from 'components/SideDialog';

type TableFilterProps<F> = {
  filters: F;
  setFilters: SetQuery<any>;
  filterFields: FilterField<F>[];
  closeFilters: () => void;
  setPage: (page: number | undefined) => void;
  transNamespace?: string;
  open?: boolean;
  helpId?: string | number;
  locator?: string;
};

export function TableFilter<F extends FilterType<F>>(props: TableFilterProps<F>) {
  const {
    filters,
    setFilters,
    filterFields,
    closeFilters,
    setPage,
    transNamespace = '',
    open = false,
    helpId,
    locator,
  } = props;
  const { t } = useTranslation('filters');

  const handleSubmit = (filters: F) => {
    closeFilters();
    setFilters(filters);
    setPage(undefined);
  };

  const handleCancel = () => {
    closeFilters();
    const clearFilters = Object.keys(filters).reduce<F>((acc, key) => (
      { ...acc, [key]: undefined }
    ), {} as F);
    setFilters(clearFilters, 'replaceIn');
  };

  return (
    <SideDialog<F>
      helpId={helpId}
      state={filters}
      open={open}
      title={t('Filter')}
      onClose={() => closeFilters()}
      onCancel={handleCancel}
      cancelText={t('Clear')}
      onSubmit={handleSubmit}
      submitText={t('Save')}
      locator={locator}
      render={(filters, setFilters) => (
        <Filter<F>
          fields={filterFields}
          filters={filters}
          setFilters={setFilters}
          transNamespace={transNamespace}
          locator={locator}
        />
      )}
    />
  );
}
